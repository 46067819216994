const React = require('react');

const T = require('prop-types');

const Prompt = require('react-router-dom').Prompt;
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogTitle } = require('@mui/material/DialogTitle');

const { useEffect, useCallback } = React;

const internals = {};

module.exports = function AlertDialog(props) {

    const {
        alertDialogOpen,
        title,
        message: Message,
        closeAlertDialog,
        confirmationCallback,
        cancelCallback,
        closeCallback,
        declineLabel = 'No',
        confirmLabel = 'Yes'
    } = props;

    const confirm = (ev) => {

        closeAlertDialog();
        if (typeof confirmationCallback === 'function') {
            confirmationCallback();
        }
    };

    const cancel = (ev) => {

        closeAlertDialog();
        if (typeof cancelCallback === 'function') {
            cancelCallback();
        }
    };

    const close = (ev) => {

        closeAlertDialog();
        if (typeof closeCallback === 'function') {
            closeCallback();
        }
    };

    useEffect(() => {

        if (!alertDialogOpen) {
            internals.initFocus = false;
        }
    }, [alertDialogOpen]);

    const contentsRef = useCallback((node) => {

        if (node && !internals.initFocus) {
            internals.initFocus = true;
            node.focus();
        }
    }, []);

    return (
        <React.Fragment>
            <Prompt when={alertDialogOpen} message={Message} />
            <Dialog
                open={alertDialogOpen}
                onClose={close}
                aria-labelledby='alert-logout-dialog-title'
                aria-describedby='alert-logout-dialog-description'
            >
                <div ref={contentsRef} tabIndex={0} data-focus-outline='radius:15'>
                    <DialogTitle id='alert-logout-dialog-title'>{title}</DialogTitle>
                    <DialogContent>
                        {typeof Message === 'function' && <Message />}
                        {typeof Message !== 'function' && <DialogContentText id='alert-logout-dialog-description'>
                            {Message}
                        </DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={cancel}
                            color='secondary'
                            data-focus-outline='radius:40'
                        >
                            {declineLabel}
                        </Button>
                        <Button
                            onClick={confirm}
                            data-focus-outline='radius:40'
                        >
                            {confirmLabel}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

module.exports.propTypes = {
    alertDialogOpen: T.bool,
    title: T.string.isRequired,
    message: T.string.isRequired,
    closeAlertDialog: T.func,
    confirmationCallback: T.func,
    cancelCallback: T.func,
    closeCallback: T.func,
    declineLabel: T.string,
    confirmLabel: T.string
};
