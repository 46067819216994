const React = require('react');
const { useState, useEffect, useCallback } = require('react');
const T = require('prop-types');

const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Popper } = require('@mui/material/Popper');
const { default: Button } = require('@mui/material/Button');
const { default: ClickAwayListener } = require('@mui/material/ClickAwayListener');
const { default: Paper } = require('@mui/material/Paper');

const FullScreenDialog = require('containers/FullScreenDialog');

const { default: Classes } = require('./styles.scss');
const { gaTemplates } = require('utils/react-ga');
const { default: styled } = require('styled-components');

const MAX_NUM_OF_PASSION_INTERESTS = 5;

const internals = {};

const StyledPaper = styled(Paper)`
  && {
    max-width: 500px;
    overflow: auto;
  }
`;

const StyledPopper = styled(Popper)`
  && {
    z-index: 500;
    &[x-placement*='top'] span.arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -0.9em;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 1em 1em 0 1em;
        border-color: ${({ theme }) => `${theme.palette.background.paper} transparent transparent transparent`};
      }
    }
    &[x-placement*='bottom'] span.arrow {
      bottom: 0;
      top: 0;
      margin-top: -0.9em;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 0em 1em 1em 1em;
        border-color: ${({ theme }) => `transparent transparent ${theme.palette.background.paper} transparent`};
      }
    }
  }
`;

const InterestPassionPopper = (props) => {

    const {
        selectedInterest,
        anchorRef,
        open,
        onClose,
        onSubmit,
        passionValues = []
    } = props;

    const [wideScreen, setWideScreen] = useState(false);
    const [mobileDevice, setMobileDevice] = useState(false);

    const mqlWide = window.matchMedia('screen and (min-width: 600px)');
    const mqlMobile = window.matchMedia('(pointer:none), (pointer:coarse)');
    const [popperOpen, setPopperOpen] = useState(false);

    const hasMaxNumOfPassions = passionValues.length >= MAX_NUM_OF_PASSION_INTERESTS;
    const isPassionAlready = passionValues.some((passionInterest) => {

        return passionInterest.id === selectedInterest.id;
    });

    const handleMediaQuery = (mediaQueryWide, mediaQueryMobile) => {

        setWideScreen(!!mediaQueryWide?.matches);
        setMobileDevice(!!mediaQueryMobile?.matches);
    };

    useEffect(() => {

        handleMediaQuery(mqlWide, mqlMobile);
        mqlWide.addListener(handleMediaQuery);
        mqlMobile.addListener(handleMediaQuery);

        return () => {

            mqlWide.removeListener(handleMediaQuery);
            mqlMobile.removeListener(handleMediaQuery);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const contentsRef = useCallback((node) => {

        if (node && !internals.initFocus) {
            internals.initFocus = true;
            setTimeout(() => node.focus());
        }
    }, []);

    useEffect(() => {

        if (!popperOpen) {
            internals.initFocus = false;
        }
    }, [popperOpen]);

    const renderAddPassionContent = () => {

        return (
            <div>
                <DialogTitle className={Classes.dialogTitle}>{'Add a Passion?'}</DialogTitle>
                <DialogContent className={Classes.dialogContent}>
                    <DialogContentText>{`Do you want to set '${selectedInterest.name}' as one of the five Interests you are most passionate about?`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={(evt) => {

                            gaTemplates.buttons('passion interests', `interest: cancel add passion interest ${selectedInterest ? selectedInterest.name : 'empty'}`);
                            handleClose(evt);
                        }}
                        color='secondary'
                        data-focus-outline='radius:40,padding:3'
                    >
                        No
                    </Button>
                    <Button
                        onClick={(evt) => {

                            const newPassionValues = [
                                ...passionValues,
                                selectedInterest
                            ];
                            gaTemplates.buttons('passion interests', `interest: submit add passion interest  ${selectedInterest ? selectedInterest.name : 'empty'}`);
                            onSubmit(newPassionValues);
                            handleClose(evt);
                        }}
                        color='primary'
                        data-focus-outline='radius:40,padding:3'
                        data-last-tab
                    >
                        Yes
                    </Button>
                </DialogActions>
            </div>
        );
    };

    const renderRemovePassionContent = () => {

        return (<div>
            <DialogTitle className={Classes.dialogTitle}>{'Remove a Passion?'}</DialogTitle>
            <DialogContent className={Classes.dialogContent}>
                <DialogContentText>{`Do you want '${selectedInterest.name}' to no longer appear as one of the Interests you are most passionate about?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(evt) => {

                        gaTemplates.buttons('passion interests', `interest: cancel remove passion interest  ${selectedInterest ? selectedInterest.name : 'empty'}`);
                        handleClose(evt);
                    }}
                    color='secondary'
                    data-focus-outline='radius:40,padding:3'
                >
                    No
                </Button>
                <Button
                    onClick={(evt) => {

                        const newPassionValues = passionValues.filter((passionInterest) => {

                            return passionInterest.id !== selectedInterest.id;
                        });
                        gaTemplates.buttons(
                            'passion interests',
                            `interest: submit remove passion interest ${selectedInterest ? selectedInterest.name : 'empty'}`
                        );
                        onSubmit(newPassionValues);
                        handleClose(evt);
                    }}
                    color='primary'
                    data-focus-outline='radius:40,padding:3'
                    data-last-tab
                >
                    Yes
                </Button>
            </DialogActions>
        </div>);
    };

    const renderLimitReachedContent = () => {

        return (<div>
            <DialogTitle className={Classes.dialogTitle}>{'Passion Limit Reached'}</DialogTitle>
            <DialogContent className={Classes.dialogContent}>
                <DialogContentText>{`You have already chosen the five Interests you are most passionate about. To make changes, first click one of your current Passions.`}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(evt) => {

                        handleClose(evt);
                    }}
                    color='primary'
                    data-focus-outline='radius:40,padding:3'
                    data-last-tab
                >
                    OK
                </Button>
            </DialogActions>
        </div>);
    };

    useEffect(() => {

        setPopperOpen(open);

        if (open){
            gaTemplates.buttons('passion interests', `interest: open passion interest  ${selectedInterest ? selectedInterest.name : 'empty'}`);
            document.activeElement.blur();
        }
        else {
            gaTemplates.buttons('passion interests', `interest: close passion interest  ${selectedInterest ? selectedInterest.name : 'empty'}`);
        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = (event) => {

        if (anchorRef && anchorRef.contains(event.target)) {
            return;
        }

        setTimeout(() => {

            anchorRef?.focus();
        }, 100);

        onClose();
        setPopperOpen(false);
    };

    const escListener = (evt) => {

        if (evt.key === 'Escape') {
            handleClose(evt);
        }
    };

    return (
        (wideScreen && !mobileDevice) ? <StyledPopper
            open={popperOpen}
            anchorEl={anchorRef}
            placement={'top'}
            disablePortal={false}
            onKeyDown={(evt) => {

                // Wrap focus in the dialog
                if (
                    evt.key === 'Tab'
                    && evt.shiftKey === true
                    && evt.target.getAttribute('data-first-tab')
                ) {
                    const lastTab = document.querySelector('[data-last-tab=true]');
                    evt.preventDefault();
                    evt.stopPropagation();
                    lastTab.focus();
                }

                // Wrap focus in the dialog
                if (
                    evt.key === 'Tab'
                    && evt.shiftKey === false
                    && evt.target.getAttribute('data-last-tab')
                ) {
                    const firstTab = document.querySelector('[data-first-tab=true]');
                    evt.preventDefault();
                    evt.stopPropagation();
                    firstTab.focus();
                }
            }}
            // modifiers={{
            //     flip: {
            //         enabled: true
            //     },
            //     preventOverflow: {
            //         enabled: true,
            //         boundariesElement: 'scrollParent'
            //     },
            //     arrow: {
            //         enabled: true,
            //         element: arrowRef
            //     }
            // }}
        >
            <StyledPaper onKeyDown={escListener}>
                <ClickAwayListener onClickAway={handleClose}>
                    <div
                        ref={contentsRef}
                        tabIndex={0}
                        data-focus-outline='radius:15'
                        data-first-tab
                    >
                        {!hasMaxNumOfPassions ?
                            (!isPassionAlready ? renderAddPassionContent() : renderRemovePassionContent()) :
                            (isPassionAlready ? renderRemovePassionContent() : renderLimitReachedContent())
                        }
                    </div>
                </ClickAwayListener>
            </StyledPaper>
        </StyledPopper> : <FullScreenDialog
            open={popperOpen}
            title={<span className={Classes.title}>Passion Interest</span>}
            onRequestClose={handleClose}
            onKeyDown={escListener}
        >
            <div
                ref={contentsRef}
                tabIndex={0}
                data-focus-outline='radius:15'
            >
                {!hasMaxNumOfPassions ?
                    (!isPassionAlready ? renderAddPassionContent() : renderRemovePassionContent()) :
                    (isPassionAlready ? renderRemovePassionContent() : renderLimitReachedContent())
                }
            </div>
        </FullScreenDialog>
    );
};

InterestPassionPopper.propTypes = {
    selectedInterest: T.object.isRequired,
    open: T.bool,
    onClose: T.func,
    onSubmit: T.func,
    passionValues: T.array,
    anchorRef: T.oneOfType([
        T.func,
        T.shape({ current: T.elementType })
    ])
};

module.exports = InterestPassionPopper;
