const React = require('react');
const T = require('prop-types');
const BottomButtonContainer = require('components/BottomButtonContainer');
const UserPreferences = require('components/UserPreferences');
const { default: Typography } = require('@mui/material/Typography');

const { default: Classes } = require('./styles.scss');

const internals = {};

module.exports = class UserPreferencesPage extends React.PureComponent {

    static propTypes = {
        onSubmit: T.func.isRequired,
        onSubmitNoRedirect: T.func,
        onInvalidSubmit: T.func,
        preferences: T.object
    }

    constructor(props) {

        super(props);

        this.onUpdateButtonPress = this._onUpdateButtonPress.bind(this);
        this.setRef = this._setRef.bind(this);
        this.onChildFormSubmit = this._onChildFormSubmit.bind(this);
        this.onChildFormSubmitNoRedirect = this._onChildFormSubmitNoRedirect.bind(this);
        this.stillExists = true;
        this.state = { submitting: false };
    }

    componentDidMount() {

        window.scrollTo(0, 0);
    }

    componentWillUnmount() {

        this.stillExists = false;
    }

    _onUpdateButtonPress() {

        this.userPreferences.submit();
    }

    _onChildFormSubmit(preferences) {

        this.setState({ submitting: true });
        return this.props.onSubmit(preferences).then(() => {

            if (this.stillExists) {
                this.setState({ submitting: false });
            }
        }).catch((err) => {

            if (this.stillExists) {
                this.setState({ submitting: false });
            }

            throw new Error(err.message,{
                cause:err.cause
            });
        });
    }
    _onChildFormSubmitNoRedirect(preferences) {

        this.setState({ submitting: true });
        this.props.onSubmitNoRedirect(preferences).then(() => {

            if (this.stillExists) {
                this.setState({ submitting: false });
            }
        }).catch((err) => {

            if (this.stillExists) {
                this.setState({ submitting: false });
            }

            throw new Error(err.message,{
                cause:err.cause
            });
        });
    }

    _setRef(varName) {

        return (ref) => {

            this[varName] = ref;
        };
    }

    render() {

        return (
            <BottomButtonContainer
                btnLabel='Save'
                onBtnClick={this.onUpdateButtonPress}
                disabled={this.state.submitting}
            >
                <div className={Classes.wrapper}>
                    <div className={Classes.innerWrapper}>
                        <Typography
                            variant='h2'
                            style={{
                                marginTop: 14,
                                marginBottom: 10
                            }}
                        >
                            User Preferences
                        </Typography>
                        <UserPreferences
                            ref={this.setRef('userPreferences')}
                            onSubmit={this.onChildFormSubmit}
                            onSubmitNoRedirect={this.onChildFormSubmitNoRedirect}
                            onInvalidSubmit={this.props.onInvalidSubmit}
                            preferencesSubmiting={this.state.submitting}
                            history={history}
                            {...this.props}
                        />
                    </div>
                </div>
            </BottomButtonContainer>
        );
    }
};
