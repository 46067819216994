const { connect } = require('react-redux');
const { context } = require('app-context');
const DetailPage = require('../components/DetailPage');
const { goBack: GoBack, push: Push } = require('connected-react-router');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state, { match: { params: { userId } } }) => ({
        key: userId,
        userDetails: selectors.getUser_forDetails(state, userId),
        interestsLoading: selectors.getIsLoadingInterests(state),
        actingUserRole: selectors.getUserRole(state, userId),
        selectedUserIsStudentGroup: selectors.isStudentRoleGroup(state, userId),
        categories: selectors.getCategories(state),
        currentUserClasses: selectors.getCurrentUser(state).classes() || null,
        currentUserInterestsIds: selectors.getCurrentUserInterestsIds(state) || null,
        conditionalDisplayFields: selectors.getConditionalDisplayFields_forUser(state, userId),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        rolesInteractions:selectors.getCurrentUserRoleInteractions(state),
        role: selectors.getCurrentUserRole(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        badges: selectors.getAllBadges(state)
    }),
    {
        onClickChat: (userId, message) => actions.communication.requestChat({ userId, message }),
        onClickConnect: (userId, message, isAutoConnect, similarityText) => actions.communication.requestPeer({ userId, message, isAutoConnect, similarityText }),
        onRequestDisconnect: ({ id: userId, firstName }) => actions.communication.disconnect({ userId, firstName }),
        onAcceptPeer: ({ id: userId, firstName }) => actions.communication.acceptPeer({ userId, firstName }),
        openAlertDialog: actions.app.openAlertDialog,
        onEnableUser: ({ id: userId }, toEnable) => actions.communication.enableUser({ userId, toEnable }),
        goBack: GoBack,
        push: Push,
        showNotification: (msg) => actions.alerts.notification.push({ message: msg })
    }
)(DetailPage);
