// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__header___PEVzH{text-align:center}.styles__header___PEVzH h2{margin:24px 0 14px}.styles__header___PEVzH h3{font-size:16px;margin-bottom:5px}`, "",{"version":3,"sources":["webpack://./src/components/AccessibilityContents/styles.scss"],"names":[],"mappings":"AACA,wBACI,iBAAkB,CADtB,2BAIQ,kBAAgB,CAJxB,2BAOQ,cAAe,CACf,iBAAkB","sourcesContent":["\n.header {\n    text-align: center;\n    h2 {\n        margin: 14px 0;\n        margin-top: 24px;\n    }\n    h3 {\n        font-size: 16px;\n        margin-bottom: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles__header___PEVzH`
};
export default ___CSS_LOADER_EXPORT___;
