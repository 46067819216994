const { default: ReactGA } = require('react-ga4');
const { LOCATION_CHANGE } = require('connected-react-router');

const debug = __DEBUG__;
const isCordovaApp = !!window.cordova;

const firebaseObj = isCordovaApp ?  window.cordova.plugins.firebase : null;
const Storage = window.localStorage;
const GetQueryParams = require('../utils/get-query-params');

const internals = {};

const {
    SIGNUP,
    NOTIFICATIONS,
    AUTH,
    PROFILE_MANAGEMENT,
    CLASSES,
    DATA_FETCHING,
    COMMUNICATION,
    APP_FEEDBACK
} = require('../action-types');

// init gaaa

if (debug) {
    console.log('INITIALIZE GA!');
}


if (!isCordovaApp){

    ReactGA.initialize(process.env.GA_TRACKING_ID, {
        gaOptions: {
            // Disables cookies.
            storage: 'none',
            // Your GA tracking id.
            trackingId: process.env.GA_TRACKING_ID,
            // Will return null if not set, and GA will then assign one.
            clientId: Storage.getItem('ga:clientId')

            //debug_mode: true



        }
    });
}

const GaUtils = {};
module.exports = GaUtils;

// Ga templates

GaUtils.gaTemplates = {

    navigationWithVal: (action, label, value) => {

        GaUtils.logEvent({
            category: 'navigation',
            action,
            label,
            value
        });
    },

    generic: (options) => {

        GaUtils.logEvent(options);
    }
};

[
    'buttons',
    'navigation',
    'events'
]
.forEach((actionLabelEventType) => {

    GaUtils.gaTemplates[actionLabelEventType] = (action, label,item_id) => {

        GaUtils.logEvent({
            category: actionLabelEventType,
            action,
            label,
            item_id
        });
    };
});

GaUtils.sendGaWithConfig = (gaConfig) => {

    if (gaConfig.length === 3) {
        const [gaTmpl, ...rest] = gaConfig;
        return GaUtils.gaTemplates[gaTmpl](...rest);
    }

    return GaUtils.gaTemplates.events(...gaConfig);
};

GaUtils.gaClickTemplate = (tmpl, action, label) => {

    return () => {

        GaUtils.gaTemplates[tmpl](action, label);
    };
};

// react-router onUpdate (for logging specific page-view actions)
// Take a look in the route configs for where these are applied

GaUtils.onRouteUpdate = (onUpdateProps) => {

    const { props: routeProps = {} } = onUpdateProps.route;
    const { ga } = routeProps;

    let gaTemplateName;
    let gaTemplateArgs;

    if (!ga) {
        return;
    }

    if (typeof ga === 'function') {
        // If it's a function, it must return { [templateName,] templateArgs }
        const { templateName, templateArgs } = ga(onUpdateProps);

        if (templateName) {
            gaTemplateName = templateName;
        }
        else {
            gaTemplateName = 'navigation';
        }

        gaTemplateArgs = templateArgs;
    }
    else if (Array.isArray(ga)) {
        const firstEl = ga[0];

        if (GaUtils.gaTemplates[firstEl]) {
            gaTemplateName = firstEl;
            gaTemplateArgs = ga.slice(1);
        }
        else {
            gaTemplateName = 'navigation';
            gaTemplateArgs = ga;
        }
    }

    // If these exist, send 'em out!
    if (gaTemplateName && gaTemplateArgs) {
        GaUtils.gaTemplates[gaTemplateName](...gaTemplateArgs);
    }
};


// redux middleware -- to listen for and respond to actions

const ignorePages = [
    '/app',
    '/app/messaging'
];

GaUtils.gaMiddleware = (store) => (next) => (action) => { // eslint-disable-line

    if (!action.payload || !action.payload.location) {
        return next(action);
    }

    // const normalizedPath = action && action.payload && action.payload.pathname && action.payload.pathname.replace(/\/$/, '');
    const { location: { pathname } } = action.payload;

    const normalizedPath = pathname === '/' ? pathname : pathname.replace(/\/$/, '');

    switch (action.type) {

        case LOCATION_CHANGE:

            if (ignorePages.indexOf(normalizedPath) === -1) {

                let loggedPath;

                // google ignores the pageView if the path is emptystring
                // So make sure this gets logged if '/' is the path
                if (pathname.split('?')[0] === '/') {
                    loggedPath = '/';
                }
                else {
                    loggedPath = normalizedPath;
                }

                const { r: rParam } = GetQueryParams();

                if (rParam) {
                    loggedPath += `?r=${rParam}`;
                }

                // TODO find a way to check if this path is valid, or if it is being redirected immediately
                GaUtils.logPageView(loggedPath);
            }

            break;

        case SIGNUP.STEP_1.COMPLETE.SUCCESS:

            GaUtils.gaTemplates.buttons('create account', 'Create account: create account');
            break;

        case NOTIFICATIONS.ACCEPT.SUCCESS:

            if (action.payload.request.type === 'peer') {
                GaUtils.gaTemplates.buttons('connection accepted', 'notifications: connection accepted');
            }

            break;

        case NOTIFICATIONS.DECLINE.SUCCESS:

            if (action.payload.request.type === 'peer') {
                GaUtils.gaTemplates.buttons('connection ignored', 'notifications: connection ignored');
            }

            break;

        case AUTH.LOGOUT.SUCCESS:


            if (isCordovaApp){
                firebaseObj.analytics.resetAnalyticsData();
            }

            GaUtils.gaTemplates.buttons('logout', 'logged out');
            break;
        case AUTH.CLOSE_ACCOUNT.SUCCESS:
        case AUTH.ACCOUNT_CLOSED_CLEAR:
            GaUtils.gaTemplates.buttons('close account', 'user closed account');
            break;

        case PROFILE_MANAGEMENT.UPDATE_PROFILE.SUCCESS:

            GaUtils.gaTemplates.buttons('my profile saved', 'my profile: profile saved');
            break;

        case CLASSES.JOIN.SUCCESS:

            GaUtils.gaTemplates.buttons('class added', 'my classes: class added');
            break;

        case CLASSES.LEAVE.SUCCESS:

            GaUtils.gaTemplates.buttons('left class', 'my classes: left class');
            break;

        case DATA_FETCHING.FETCH_CLASS.SUCCESS:

            GaUtils.gaTemplates.buttons('entered class', 'my classes: entered class');
            break;

        case COMMUNICATION.REQUEST_PEER.SUCCESS:

            GaUtils.gaTemplates.buttons('peer connection requested', 'peer profile: connection requested');
            break;

        case COMMUNICATION.REQUEST_CHAT.SUCCESS:

            GaUtils.gaTemplates.buttons('peer chat requested', 'peer profile: chat requested');
            break;

        case APP_FEEDBACK.SUBMIT_BUG_REPORT.SUCCESS:

            GaUtils.gaTemplates.buttons('support submitted', `support: ${action.payload.request.bugType}`);
            break;
    }

    return next(action);
};


// Building blocks

GaUtils.logEvent = (options) => {

    if (debug) {
        console.log('LOG EVENT!');
        console.log(JSON.stringify(options));
    }

    /*
    options can contain:
    {
        category,
        action,
        label,
        value,
        nonInteraction
    }
    */

    // Dimension18 is `app` -- set to 1 if in-app, 0 if not
    const dimension18 = isCordovaApp ? '1' : '0';

    const eventActionName = options.action.toLowerCase().replace(/ /g,'_');

    if (isCordovaApp){

        firebaseObj.analytics.logEvent(eventActionName, {
            category: options.category,
            action: eventActionName,
            label: options.label,
            dimension18,
            item_id:options && options.item_id ? options.item_id : undefined
        });
    }
    else {
        ReactGA.gtag('event',eventActionName,{
            category: options.category,
            action: eventActionName,
            label: options.label,
            dimension18,
            item_id:options && options.item_id ? options.item_id : undefined
        });
    }



};


GaUtils.setValue = (key, value) => {

    if (debug) {
        console.log('SET VALUE!');
        console.log(key + ': ', value);
    }

    if ( !isCordovaApp){
        const gaSet = {};
        gaSet[key] = value;

        ReactGA.set(gaSet);
    }
};


GaUtils.logPageView = (pathName) => {

    if (debug) {
        console.log('LOG PAGE VIEW!');
        console.log(pathName);
    }

    // Dimension18 is `app` -- set to 1 if in-app, 0 if not


    const dimension18 = isCordovaApp ? '1' : '0';


    if (isCordovaApp){
        firebaseObj.analytics.logEvent('page_view', {
            pathName,
            page:pathName,
            'page_path':pathName,
            dimension18
        });
    }
    else {
        ReactGA.set({ page: pathName });
        ReactGA.gtag('event', 'page_view', {
            'page_path':pathName,
            page:pathName,
            dimension18
        });
    }

};


GaUtils.getUserId = () => {

    return GaUtils.userId;
};

// These 2 funcs get called from initializers/react-ga

GaUtils.setUserId = (userId) => {

    if (userId === GaUtils.userId) {
        return;
    }

    GaUtils.userId = userId;

    if (isCordovaApp){

        firebaseObj.analytics.setUserId(`${userId}`);
        firebaseObj.analytics.logEvent('login');
    }
    else {
        GaUtils.setValue('userId', GaUtils.userId);

        // The 'login button tap' event
        GaUtils.gaTemplates.buttons('login', 'user logged in');
    }

};

GaUtils.unsetUserId = () => {

    if (GaUtils.userId !== null) {
        if (isCordovaApp){
            GaUtils.userId = null;
            firebaseObj.analytics.setUserId(null);
        }
        else {
            GaUtils.userId = null;
            GaUtils.setValue('userId', null);
        }
    }
};
