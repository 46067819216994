// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__homeBody___c6nLm{background-color:#f7f7f7}.styles__textWrapper___aF7Ez{align-items:center;display:flex;flex-direction:column;justify-content:center;min-height:calc(100vh - 64px)}`, "",{"version":3,"sources":["webpack://./src/routes/login/components/PakistanNearpeerAttention/styles.scss","webpack://./../../styles/colors.json"],"names":[],"mappings":"AAEA,0BACE,wBCCuB,CDAxB,6BAMC,kBAAa,CAHb,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CAHvB,6BAImB","sourcesContent":["@import \"../../../../styles/colors.json\";\n\n.homeBody {\n  background-color: $emptyBackground;\n}\n.textWrapper{\n  min-height: calc(100vh - 64px);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n","$darkBrandColor: #4611a9;\n$lightHighlight: #a4e4d3;\n$lightText: white;\n$darkYellow: #a97611;\n$emptyBackground: #f7f7f7;\n$darkText: black;\n$muiSecondary: #A10036;\n$disabledColor: rgba(0, 0, 0, 0.6);\n$lightGrey: rgb(224, 224, 224);\n$mediumGrey: rgb(188, 188, 188);\n$darkGrey: rgb(88, 88, 88);\n$muiPrimary: rgb(70, 17, 169);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeBody": `styles__homeBody___c6nLm`,
	"textWrapper": `styles__textWrapper___aF7Ez`
};
export default ___CSS_LOADER_EXPORT___;
