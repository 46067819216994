const React = require('react');
const T = require('prop-types');
const { ConnectedRouter } = require('connected-react-router');
const { Routes } = require('../utils/strange-router-v3-shim');
const { Provider } = require('react-redux');
const Styled = require('styled-components');
const { HelmetProvider } = require('react-helmet-async');
const { StyledEngineProvider, ThemeProvider:MuiThemeProvider } = require('@mui/material/styles');

const MuiTheme = require('../styles/theme');
const GA = require('../utils/react-ga');

//LATO
require('../styles/offlineFonts/Lato/Lato-Light.woff2');
require('../styles/offlineFonts/Lato/Lato-Regular.woff2');
require('../styles/offlineFonts/Lato/Lato-Bold.woff2');
require('../styles/offlineFonts/Lato/Lato-Black.woff2');
require('../styles/offlineFonts/Lato/Lato.scss');

//MULISH
require('../styles/offlineFonts/Mulish/Mulish-Regular.woff2');
require('../styles/offlineFonts/Mulish/Mulish-Bold.woff2');
require('../styles/offlineFonts/Mulish/Mulish-ExtraBold.woff2');
require('../styles/offlineFonts/Mulish/Mulish.scss');

//ROBOTO SLAB
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Light.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Regular.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Bold.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-ExtraBold.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab-Black.woff2');
require('../styles/offlineFonts/Roboto_Slab/RobotoSlab.scss');

// Pull global styles
require('../styles/core.scss');

module.exports = class App extends React.Component {

    static propTypes = {
        history: T.object.isRequired,
        routes: T.array.isRequired,
        store: T.object.isRequired,
        theme: T.object
    }

    componentDidMount() {

        window.dispatchEvent(new Event('resize'));
    }

    render() {

        const { history, routes, store, theme = MuiTheme } = this.props;

        return (
            <HelmetProvider>
                <Provider store={store}>
                    <Styled.ThemeProvider theme={theme}>
                        <StyledEngineProvider injectFirst>
                            <MuiThemeProvider theme={theme}>
                                <ConnectedRouter history={history}>
                                    <Routes
                                        routes={routes}
                                        onUpdate={GA.onRouteUpdate}
                                    />
                                </ConnectedRouter>
                            </MuiThemeProvider>
                        </StyledEngineProvider>
                    </Styled.ThemeProvider>
                </Provider>
            </HelmetProvider>
        );
    }
};
