const { connect } = require('react-redux');
const { context } = require('../app-context');
const SearchUsersFilterDialog = require('components/SearchUsersFilterDialog');
const { gaTemplates } = require('../utils/react-ga');
const { USERS_SORT_TYPE } = require('../utils/constants');

const internals = {};
const selectors = context.selectors.all;
const actions = context.actions;
module.exports = connect(
    (state) => ({
        interests: selectors.getInterests(state),
        majors: selectors.getMajorsList_forCurrentUser(state),
        incomingClassList: selectors.getIncomingClassList_forCurrentUser(state),
        graduatingClassList: selectors.getGraduatingClassList_forCurrentUser(state),
        isParent: selectors.isParentPermission(state),
        currentUserPermissions: selectors.getCurrentUserRolePermissions(state),
        departments: selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        officesList: selectors.getAllOffices(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        canSearchHousing: selectors.getCanSearchHousing_forUserSearch(state),
        canSearchHometown: selectors.getCanSearchHometown_forUserSearch(state),
        schoolRoles: selectors.getSchoolRoles(state),
        conditionalDisplayFields: selectors.getConditionalDisplayFields_forCurrentUser(state),
        activeSearchFilter:selectors.getNotifySearchFilter(state),
        badges: selectors.getAllBadges(state)
    }),
    {
        // eslint-disable-next-line hapi/no-arrowception
        onSubmit: (criteria, sortType = USERS_SORT_TYPE.ALPHABETICAL) => (dispatch) => { // eslint-disable-line hapi/no-arrowception, hapi/hapi-scope-start

            const filteredCriteria = criteria ? internals.filterOutEmpty(criteria) : [];

            const searchKeys = criteria ? Object.keys(filteredCriteria) : [];

            // Default back to 'allByMatches: true' if 'includeStaff' is true
            if (searchKeys.length === 1 && filteredCriteria.includeStaff) {
                delete filteredCriteria.includeStaff;
            }

            gaTemplates.buttons('submit notify users search', `discover: search submit ${searchKeys.length ? searchKeys : 'empty'}`);

            if (searchKeys.length === 0) {
                return dispatch(actions.dataFetching.fetchUserSearchNotifyResults({
                    allByMatches: true,
                    sortType
                }));
            }

            const { interests, ...others } = filteredCriteria;

            const search = { ...others };

            if (interests) {
                search.interests = interests.map((x) => x.id);
            }

            return dispatch(actions.dataFetching.fetchUserSearchNotifyResults({
                ...search,
                sortType
            }));
        },
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        resetSearch: () => (dispatch) => {

            dispatch(actions.app.setNotifySearchFilter({ filter : {} }));
            return dispatch(actions.dataFetching.fetchUserSearchNotifyResults({
                allByMatches: true,
                sortType: USERS_SORT_TYPE.ALPHABETICAL
            }));
        }
    }
)(SearchUsersFilterDialog);

internals.filterOutEmpty = (obj) => {

    const { isBool } = internals;

    return Object.keys(obj).filter((key) => {

        const value = obj[key];

        if (Array.isArray(value)) {
            return !!value.length;
        }

        return isBool(value) || !!value;
    })
    .reduce((collector, key) => {

        collector[key] = obj[key];
        return collector;
    }, {});
};

internals.isBool = (maybeBool) => {

    if (String(maybeBool) === 'true' || String(maybeBool) === 'false') {
        return true;
    }

    return false;
};
