// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__contents___ybEAP{padding:.5em 2em 50px}`, "",{"version":3,"sources":["webpack://./src/routes/login/components/LoginHelp/styles.scss"],"names":[],"mappings":"AAEA,0BACI,qBAAsB","sourcesContent":["@import \"../../../../styles/colors.json\";\n\n.contents {\n    padding: .5em 2em 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contents": `styles__contents___ybEAP`
};
export default ___CSS_LOADER_EXPORT___;
