// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___MwLon{padding:1.5em;width:100%}.styles__header___AAcJh{align-items:center;display:flex;justify-content:space-between;margin-top:12px}.styles__header___AAcJh .styles__headerTitle___wpIJ5{font-size:28px}.styles__header___AAcJh .styles__createNewButton___GSu6s{height:40px}`, "",{"version":3,"sources":["webpack://./src/routes/app/surveys/components/MySurveys/styles.scss"],"names":[],"mappings":"AAAA,yBAEE,aAAS,CADT,UACc,CACf,wBAIC,kBAAmB,CAFnB,YAAa,CACb,6BAA8B,CAE9B,eAAgB,CAJlB,qDAOI,cAAe,CAPnB,yDAWI,WAAY","sourcesContent":[".wrapper {\n  width: 100%;\n  padding: 1.5em;\n}\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 12px;\n\n  .headerTitle {\n    font-size: 28px;\n  }\n\n  .createNewButton {\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___MwLon`,
	"header": `styles__header___AAcJh`,
	"headerTitle": `styles__headerTitle___wpIJ5`,
	"createNewButton": `styles__createNewButton___GSu6s`
};
export default ___CSS_LOADER_EXPORT___;
