const React = require('react');
const { useState, useEffect, useRef, useCallback } = require('react');
const T = require('prop-types');

const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Popper } = require('@mui/material/Popper');
const { default: Button } = require('@mui/material/Button');
const { default: ClickAwayListener } = require('@mui/material/ClickAwayListener');
const { default: Paper } = require('@mui/material/Paper');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: styled } = require('styled-components');

const FullScreenDialog = require('containers/FullScreenDialog');

const { default: Classes } = require('./styles.scss');
const { gaTemplates } = require('utils/react-ga');
const { INTERESTS } = require('utils/constants');
const { default: Dialog } = require('@mui/material/Dialog');

const StyledPaper = styled(Paper)`
  && {
    max-width: 400px;
    overflow: auto;
  }
`;

const StyledPopper = styled(Popper)`
  && {
    z-index: 500;
    &[x-placement*='top'] span.arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -0.9em;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 1em 1em 0 1em;
        border-color: ${({ theme }) => `${theme.palette.background.paper} transparent transparent transparent`};
      }
    }
    &[x-placement*='bottom'] span.arrow {
      bottom: 0;
      top: 0;
      margin-top: -0.9em;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 0em 1em 1em 1em;
        border-color: ${({ theme }) => `transparent transparent ${theme.palette.background.paper} transparent`};
      }
    }
  }
`;

const StyledCheckControlLabel = styled(FormControlLabel)`
  margin: 0;
  float: right;
`;

const internals = {};

const InterestPopper = (props) => {

    const {
        interest,
        anchorRef,
        open,
        onClose,
        onSubmitNewInterest,
        userPreferences,
        updateUserPreferences,
        currentUserInterestsIds
    } = props;

    const [popperOpen, setPopperOpen] = useState(false);
    const [notAsking, setNotAsking] = useState(false);
    const [interestLimitOpenDialog, setInterestLimitOpenDialog] = useState(false);

    const askAgainCheckbox = useRef();

    const [wideScreen, setWideScreen] = useState(false);
    const [mobileDevice, setMobileDevice] = useState(false);

    const mqlWide = window.matchMedia('screen and (min-width: 600px)');
    const mqlMobile = window.matchMedia('(pointer:none), (pointer:coarse)');

    const handleMediaQuery = (mediaQueryWide, mediaQueryMobile) => {

        setWideScreen(!!mediaQueryWide?.matches);
        setMobileDevice(!!mediaQueryMobile?.matches);
    };

    useEffect(() => {

        handleMediaQuery(mqlWide,mqlMobile);
        mqlWide.addListener(handleMediaQuery);
        mqlMobile.addListener(handleMediaQuery);

        return () => {

            mqlWide.removeListener(handleMediaQuery);
            mqlMobile.removeListener(handleMediaQuery);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const interestLimitRef = useCallback((node) => {

        if (node && !internals.interestLimitOpenDialogInitFocus) {
            internals.interestLimitOpenDialogInitFocus = true;
            setTimeout(() => node.focus());
        }
    }, []);

    useEffect(() => {

        if (!interestLimitOpenDialog) {
            internals.interestLimitOpenDialogInitFocus = false;
        }
    }, [interestLimitOpenDialog]);

    const interestDialogRef = useRef();

    const interestDialogRefCb = useCallback((node) => {

        interestDialogRef.current = node;
        if (node && !internals.interestDialogInitFocus) {
            internals.interestDialogInitFocus = true;
            setTimeout(() => node.focus());
        }
    }, []);

    useEffect(() => {

        if (!popperOpen) {
            internals.interestDialogInitFocus = false;
        }
    }, [popperOpen]);


    const handleClose = (event) => {

        if (anchorRef && anchorRef.contains(event.target)) {
            return;
        }

        setTimeout(() => {

            anchorRef?.focus();
        }, 100);

        onClose();
        setPopperOpen(false);
    };

    useEffect(() => {

        if (open && (userPreferences.hideInterestPopup === 'false' || userPreferences.hideInterestPopup === '')){
            setPopperOpen(open);
        }
        else {
            gaTemplates.buttons('interests', `interest: submit add interest  ${interest ? interest.name : 'empty'}  from another profile`);

            if (currentUserInterestsIds.length === INTERESTS.LIMIT || currentUserInterestsIds.length > INTERESTS.LIMIT){
                setInterestLimitOpenDialog(true);
            }
            else {
                onSubmitNewInterest(interest.id);
                handleClose({});
            }
        }
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeNotAsking = (event) => {

        setNotAsking(!notAsking);
    };

    const escListener = (evt) => {

        if (evt.key === 'Escape') {
            handleClose(evt);
        }
    };

    const renderContent = () => {

        return (
            <div
                onKeyDown={(evt) => {

                    // Wrap focus in the dialog
                    if (
                        evt.key === 'Tab'
                        && evt.shiftKey === false
                        && Array.from(document.activeElement.classList).includes('PrivateSwitchBase-input')
                    ) {
                        evt.preventDefault();
                        evt.stopPropagation();
                        interestDialogRef.current?.focus();
                    }
                }}
            >
                <DialogTitle>{'Add new Interest?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Add '${interest.name}' to your own list of Interests?`}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={(evt) => {

                            gaTemplates.buttons('interests', `interest: cancel add interest ${interest ? interest.name : 'empty'} from another profile`);

                            if (notAsking) {

                                updateUserPreferences({
                                    ...userPreferences,
                                    hideInterestPopup: notAsking.toString()
                                });
                            }

                            handleClose(evt);
                        }}
                        color='secondary'
                        data-focus-outline='radius:40,padding:3'
                    >
                        No
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={
                            (evt) => {

                                if (currentUserInterestsIds.length === INTERESTS.LIMIT){
                                    setInterestLimitOpenDialog(true);
                                }
                                else {
                                    onSubmitNewInterest(interest.id);
                                    gaTemplates.buttons('interests', `interest: submit add interest ${interest ? interest.name : 'empty'} from another profile`);
                                    if (notAsking){

                                        updateUserPreferences({
                                            ...userPreferences,
                                            hideInterestPopup: notAsking.toString()
                                        });
                                    }

                                    handleClose(evt);
                                }
                            }
                        }
                        color='primary'
                        data-focus-outline='radius:40,padding:3'
                    >
                        Yes
                    </Button>
                </DialogActions>
                <StyledCheckControlLabel
                    label={'Don\'t ask me again'}
                    labelPlacement='start'
                    ref={askAgainCheckbox}
                    control={<Checkbox
                        checked={!!notAsking}
                        onChange={onChangeNotAsking}
                        inputProps={{ 'data-focus-outline': 'radius:40,padding:-2' }}
                    />}
                />
            </div>
        );
    };

    return (
        <React.Fragment>
            <Dialog
                onKeyDown={escListener}
                open={interestLimitOpenDialog}
                onClose={(evt) => {

                    setInterestLimitOpenDialog(false);
                    handleClose(evt);
                } }
                aria-labelledby='alert-Inappropriate-Message-title'
                aria-describedby='alert-Inappropriate-Message-description'
            >
                <div
                    ref={interestLimitRef}
                    tabIndex={0}
                    data-focus-outline='radius:15'
                >
                    <DialogTitle id='alert-Inappropriate-Message-title'>{'Warning'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-Inappropriate-Message-description'>
                            {`Sorry, you have exceeded the maximum number of ${INTERESTS.LIMIT} Interests. Please remove some before adding new Interests`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(evt) => {

                                setInterestLimitOpenDialog(false);
                                handleClose(evt);
                            }}
                            color='primary'
                            data-focus-outline='radius:40,padding:3'
                        >
                            OK
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {(wideScreen && !mobileDevice) ? <StyledPopper
                open={popperOpen}
                anchorEl={anchorRef}
                placement={'top'}
                disablePortal={false}
                onKeyDown={escListener}
                // modifiers={{
                //     flip: {
                //         enabled: true
                //     },
                //     arrow: {
                //         enabled: true,
                //         element: arrowRef
                //     }
                // }}
            >
                <StyledPaper
                    ref={interestDialogRefCb}
                    tabIndex={0}
                    data-focus-outline='radius:15'
                    onKeyDown={(evt) => {

                        // Wrap focus in the dialog
                        if (
                            evt.key === 'Tab'
                            && evt.shiftKey === true
                            && document.activeElement === interestDialogRef.current
                        ) {
                            evt.preventDefault();
                            evt.stopPropagation();
                            askAgainCheckbox.current?.focus();
                        }
                    }}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        {renderContent()}
                    </ClickAwayListener>
                </StyledPaper>
            </StyledPopper> : <FullScreenDialog
                open={popperOpen}
                title={<span className={Classes.title}>Interests</span>}
                onRequestClose={handleClose}
                onKeyDown={escListener}
            >
                {renderContent()}
            </FullScreenDialog>}
        </React.Fragment>
    );
};

InterestPopper.propTypes = {

    interest: T.object.isRequired,
    userPreferences: T.object,
    updateUserPreferences: T.func,
    open: T.bool,
    onClose: T.func,
    currentUserInterestsIds: T.array,
    onSubmitNewInterest: T.func,
    anchorRef:  T.oneOfType([
        T.func,
        T.shape({ current: T.elementType })
    ])
};

module.exports = InterestPopper;
