const { connect } = require('react-redux');
const { context } = require('app-context');
const PreapprovedEmailUpload = require('../components/PreapprovedEmailUpload');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state)
    }), {
        onSubmit: actions.communication.uploadPreapproved
    }
)(PreapprovedEmailUpload);
