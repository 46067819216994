const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@mui/material/TextField');
const { default: Typography } = require('@mui/material/Typography');
const { NavLink: Link } = require('react-router-dom');

const MaterialReactAutocomplete = require('../material-react-autocomplete');
const BottomButton = require('components/BottomButton');
const { gaTemplates } = require('utils/react-ga');
const IsEmail = require('utils/is-email');
const FixMuiMultilineInput = require('utils/fixMui4MultilineAriaLabel');
const FuzzyFilter = require('utils/fuzzyFilter');
const SelectField = require('components/SelectField');

const { createRef } = React;
// Let's borrow the styles from the BugReportPage
const Classes = require('../../routes/app/bugReport/components/BugReportPage/styles.scss');

const internals = {};

const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

module.exports = class LoginHelpContents extends React.PureComponent {

    static propTypes = {
        onSubmitBugReport: T.func,
        schools: T.array.isRequired,
        signupContext: T.object
    }

    constructor(props) {

        super(props);

        const {
            schools,
            signupContext: {
                email,
                roleId,
                schoolId
            }
        } = props;

        this.fields = [
            'bugDetails',
            'name',
            'email',
            'role',
            'schoolId'
        ];

        const maybeSchool = schools.find(({ id }) => id === schoolId);

        this.state = {
            email: email || '',
            bugDetails: '',
            name: '',
            role: roleId || 'student',
            schoolId: schoolId || '',
            schoolName: maybeSchool?.name || '',
            nameErrorShow: null,
            emailErrorShow: null,
            bugDetailsErrorShow: null,
            submitting: false
        };

        this.onFieldChange = this._onFieldChange.bind(this);
        this.error = this._error.bind(this);
        this.validate = this._validate.bind(this);
        this.submit = this._submit.bind(this);
        this.stillExists = true;
        this.setSchoolNameText = this._setSchoolNameText.bind(this);
        this.setSchoolId = this._setSchoolId.bind(this);
        this.onRoleChange = this._onRoleChange.bind(this);
        this.hasError = this._hasError.bind(this);

        this.bugDetails = createRef();
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        FixMuiMultilineInput(this.bugDetails.current, 'bugDetails-input');
    }

    componentWillUnmount() {

        this.stillExists = false;
    }

    _onFieldChange(field) {

        return (ev, ...a) => {

            const value = ev.target.value;
            this.setState({ [field]: value });
        };
    }

    clearError(field) {

        return () => {

            return this.setState({ [`${field}ErrorShow`]: false });
        };
    }

    _validate(field) {

        const value = this.state[field];
        const searchText = this.state.schoolName;

        switch (field) {
            case 'bugDetails':
                if (!value || value === '' || value.length < 1) {
                    return 'Description is required';
                }

                break;
            case 'email':
                if (!value) {
                    return 'Email is required';
                }

                if (value && !IsEmail(value)) {
                    return 'Invalid email address';
                }

                break;
            case 'name':

                if (!value || !value.match(/\S\s\S/)) {
                    return 'First and Last name is required';
                }

                break;
            case 'schoolId':

                if (!value) {
                    if (searchText) {
                        return `You must select ${isTeamsApp ? 'team' : 'college or university'} from the dropdown`;
                    }

                    return `${isTeamsApp ? 'Team' : 'College or University'} is required`;
                }

                break;
        }

        return null;
    }

    _setSchoolNameText(ev) {

        this.setState({ schoolName: ev?.target?.value || '' });
    }

    _setSchoolId(event, { suggestion: { id = null } = {}, suggestionValue }) {

        this.setState({
            schoolId: id,
            schoolName: suggestionValue,
            schoolIdError: null
        });
    }

    clearSchoolField = () => {

        this.setState({
            schoolId: null,
            schoolName: ''
        });
    }

    _onRoleChange(ev) {

        const value = ev.target.value;
        this.setState({ role: value });
    }

    _hasError(field) {

        return this.validate(field) !== null;
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? this.hasError(field) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        return this.validate(field);
    }

    _submit() {

        gaTemplates.buttons('submit support', 'support: submit');

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display
            return this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;

                if (this.validate(field)) {
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;

            }, {}));
        }

        // No errors?  Submit the field/values

        const {
            bugDetails,
            email,
            role,
            schoolId,
            schoolName,
            name
        } = this.state;

        this.setState({ submitting: true });

        // We're sharing the onSubmitBugReport action with BugReport
        // bugType is a select option there, but here it'll only be 'Trouble Logging In'

        this.props.onSubmitBugReport({
            bugType: 'Trouble Logging In',
            bugDetails: {
                description: bugDetails,
                role,
                schoolName: schoolId ? schoolName : '',
                deviceType: '',
                browserType: '',
                name
            },
            email
        }).then(() => {

            if (this.stillExists) {
                this.setState({ submitting: false });
            }
        });
    }

    render() {

        const { schools } = this.props;
        const { email, role, name, schoolId, schoolName } = this.state;

        const {
            MainContainer,
            ContentContainer,
            StyledTextField,
            BottomButtonContainer
        } = internals;

        const filteredSchools = schools ? schools.filter((school) => {

            return isTeamsApp ? school.isCompany : !school.isCompany;
        }) : [];

        const sortSchools = () => {

            const searchText = this.state.schoolName.toLowerCase();
            const schoolMatches = (school) => school.name.toLowerCase().startsWith(searchText);

            return [...filteredSchools].sort((schoolA, schoolB) => {

                return schoolMatches(schoolB) - schoolMatches(schoolA);
            });
        };

        const sortedSchools = filteredSchools && Array.isArray(filteredSchools) ? sortSchools() : [];

        return <MainContainer>
            <ContentContainer
                className={Classes.bugReportWrapper}
            >
                <div className={Classes.bugReportInnerWrapper}>
                    <Typography
                        variant='h2'
                        style={{
                            marginTop: 25,
                            marginBottom: 20
                        }}
                    >
                        Get Help Logging In
                    </Typography>
                    <Typography>
                        1. If you have not yet received an email with a verification or reset link within 10 minutes, please check your Spam folder.
                        <br />
                        2. Please make sure you are using the same email address you originally created your account with.
                        <br />
                        3. If you&apos;re not sure what your password is, you can reset it <Link to={`/forgot-password`}>here</Link>.
                        <br />
                        4. If you are still having trouble, please complete and submit the form below:
                    </Typography>
                    <StyledTextField
                        id='email-input'
                        type='email'
                        variant='outlined'
                        label='Email Address'
                        fullWidth
                        error={this.state.emailErrorShow}
                        value={email}
                        aria-invalid={this.error('email')}
                        helperText={this.error('email')}
                        onChange={this.onFieldChange('email')}
                        onFocus={this.clearError('email')}
                        onBlur={this.showError('email')}
                    />
                    <StyledTextField
                        id='name-input'
                        variant='outlined'
                        label='Full Name'
                        fullWidth
                        error={this.state.nameErrorShow}
                        value={name}
                        aria-invalid={this.error('name')}
                        helperText={this.error('name')}
                        onChange={this.onFieldChange('name')}
                        onFocus={this.clearError('name')}
                        onBlur={this.showError('name')}
                    />
                    <div style={{ position: 'relative', marginTop: 14 }}>
                        <MaterialReactAutocomplete
                            label={isTeamsApp ? 'Your Team' : 'Your College or University'}
                            openOnFocus
                            variant='outlined'
                            onChange={this.setSchoolId}
                            onTextChange={this.setSchoolNameText}
                            searchText={schoolName}
                            value={schoolId}
                            dataSource={sortedSchools}
                            id={'university-input'}
                            dataSourceConfig={{
                                text: 'name',
                                value: 'id'
                            }}
                            handleClearField={this.clearSchoolField}
                            helperText={this.error('schoolId')}
                            error={this.state.schoolIdErrorShow}
                            placeholder={'Start typing to view list'}
                            listStyle={Classes.suggestionsList}
                            filter={FuzzyFilter}
                            maxSearchResults={10}
                            onFocus={this.clearError('schoolId')}
                            onBlur={() => {

                                setTimeout(() => {

                                    this.showError('schoolId');
                                }, 500);
                            }}
                        />
                    </div>
                    <StyledTextField
                        fullWidth
                        variant='outlined'
                        placeholder='More details help us assist you better'
                        helperText={this.error('bugDetails')}
                        error={this.state.bugDetailsErrorShow}
                        label='Describe Your Problem'
                        InputProps={{
                            'aria-labelledby': 'bugDetails-label'
                        }}
                        id={'bugDetails'}
                        multiline={true}
                        ref={this.bugDetails}
                        onChange={this.onFieldChange('bugDetails')}
                        maxRows={4}
                        onFocus={this.clearError('bugDetails')}
                        onBlur={this.showError('bugDetails')}
                    />
                    <Typography style={{ paddingTop: 14 }}>Your Role</Typography>
                    <SelectField
                        value={role}
                        onChange={this.onRoleChange}
                        options={[
                            { value: 'student', label: 'Student' },
                            { value: 'staff', label: 'Staff' },
                            { value: 'parent', label: 'Parent' },
                            { value: 'other', label: 'Other' }
                        ]}
                    />
                    <div style={{ textAlign: 'center', marginTop: '15px' }}>
                        <Link to="/login" style={{ textDecoration: 'underline', color: 'blue', marginRight: '15px' }}>Back to Login</Link>
                        <Link to="/signup" style={{ textDecoration: 'underline', color: 'blue' }}>Create Account</Link>
                    </div>
                </div>
            </ContentContainer>
            <BottomButtonContainer>
                <BottomButton label='Submit' onClick={this.submit} disabled={this.state.submitting} />
            </BottomButtonContainer>
        </MainContainer>;
    }
};

internals.styles = {
    radioBtn: {
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    sectionHeader: {
        fontSize: 24,
        fontWeight: 'bold'
    }
};

internals.MainContainer = Styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto;
    max-width: 600px;
`;

internals.ContentContainer = Styled.div`
    flex-grow: 1;
    padding-bottom: 110px;
`;

internals.StyledTextField = Styled(TextField)`
    margin-top: 5px;
`;

internals.BottomButtonContainer = Styled.div`
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
`;
