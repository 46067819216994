// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__header___aaLkr{text-align:center}.styles__header___aaLkr h2{margin:24px 0 14px}.styles__header___aaLkr h3{font-size:16px;margin-bottom:5px}ol{padding-left:20px}ol li{padding-left:10px}ol li>p{margin-left:-30px}ol ol li{margin-top:10px}`, "",{"version":3,"sources":["webpack://./src/components/TosContents/styles.scss"],"names":[],"mappings":"AACA,wBACI,iBAAkB,CADtB,2BAIQ,kBAAgB,CAJxB,2BAOQ,cAAe,CACf,iBAAkB,CACrB,GAID,iBAAkB,CADtB,MAGQ,iBAAkB,CAH1B,QAMY,iBAAkB,CAN9B,SAUQ,eAAgB","sourcesContent":["\n.header {\n    text-align: center;\n    h2 {\n        margin: 14px 0;\n        margin-top: 24px;\n    }\n    h3 {\n        font-size: 16px;\n        margin-bottom: 5px;\n    }\n}\n\nol {\n    padding-left: 20px;\n    li {\n        padding-left: 10px;\n\n        & > p {\n            margin-left: -30px;\n        }\n    }\n    ol li {\n        margin-top: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles__header___aaLkr`
};
export default ___CSS_LOADER_EXPORT___;
