const { connect } = require('react-redux');
const { context } = require('app-context');
const Entry = require('../components/Entry');
const { push: Push } = require('connected-react-router');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        schools: selectors.getSchools(state),
        platformName: selectors.getPlatformName(state),
        signupDetails: selectors.getSignupDetailsForStep1(state),
        showSchoolList: true,
        text: 'Life is better when you\'re part of the community',
        secondaryText: 'Sign up with a preapproved email:',
        logo: null
    }), {
        onSubmit: actions.signup.step1.complete,
        onInvalidSubmit: actions.signup.step1.failure,
        push: Push
    }
)(Entry);
