const { connect } = require('react-redux');
const { context } = require('app-context');
const EditClassPage = require('../components/EditClassPage');

const actions = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state, { match: { params } }) => ({
        classDetails: selectors.getClass_forClassDetail(state, params.id),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state),
        globalInputPageHasError: selectors.getInputPageHasErrors(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state)
    }),
    {
        onSubmit: actions.classes.update,
        setGlobalInputPageError: actions.app.setGlobalInputPageError,
        removeGlobalInputPageError: actions.app.removeGlobalInputPageError
    }
)(EditClassPage);
