const React = require('react');

const { default: Typography } = require('@mui/material/Typography');

const { default: Classes } = require('./styles.scss');
const PlainLayout = require('layouts/PlainLayout');
const { NavLink: Link } = require('react-router-dom');
const { gaTemplates } = require('utils/react-ga');
const CenteredLayout = require('components/CenteredLayout');
const Footer = require('components/Footer');
const NearpeerLogo = require('static/nearpeer_logo_white.svg');

module.exports = class TokenNotExists extends React.PureComponent {

    componentDidMount() {

        document.body.classList.add(Classes.homeBody);
    }

    componentWillUnmount() {

        document.body.classList.remove(Classes.homeBody);
    }

    render() {

        return (
            <PlainLayout>
                <CenteredLayout
                    footer={<Footer/>}
                    mainClass={Classes.mainClass}
                    footerClass={Classes.footerClass}
                >
                    <div style={{ margin: '.67em 0 0', fontSize: '2.75em' }}>
                        <img style={{ height:'1em' }} src={NearpeerLogo} alt='' />
                    </div>
                    <h1 className={Classes.header}>Reset Link Expired</h1>
                    <div className={Classes.inputs} onKeyDown={this.onKeyDown}>
                        <Typography align={'center'} variant={'h5'}>
                            Your password reset link has expired. Please Log In or Reset again.
                        </Typography>
                    </div>
                    <br />
                    <Link
                        className={Classes.link}
                        style={{ textDecoration: 'none' }}
                        to='/login'
                        data-focus-outline='radius:4,padding:5'
                    >
                        Log In
                    </Link>
                    <Link
                        onClick={() => gaTemplates.buttons('login', 'login: forgot password')}
                        className={Classes.link}
                        to='/forgot-password'
                        data-focus-outline='radius:4,padding:5'
                    >
                        Forgot Password
                    </Link>
                    <div className={Classes.spacer} />
                </CenteredLayout>
            </PlainLayout>
        );
    }
};
