const React = require('react');
const T = require('prop-types');

const { default: Typography } = require('@mui/material/Typography');
const { default: TextField } = require('@mui/material/TextField');
const { default: SelectField } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: InputLabel } = require('@mui/material/InputLabel');

const BottomButtonContainer = require('components/BottomButtonContainer');
const { gaTemplates } = require('utils/react-ga');

const { default: Classes } = require('./styles.scss');
const { default: FormHelperText } = require('@mui/material/FormHelperText');

const { createRef } = React;

const FixMuiMultilineAriaLabel = require('utils/fixMui4MultilineAriaLabel');
const SupportPagePreventNavigationDialog = require('components/SupportPagePreventNavigationDialog');
const AlertDialog = require('containers/AlertDialog');

const internals = {
    bugTypes: [
        'Report a Bug',
        'Request Support',
        'Report Inappropriate Use',
        'Make a Suggestion',
        'Suggest a New Interest',
        'Suggest a New Conversation Starter',
        'Close My Account'
    ],
    deviceTypes: [
        'Web Browser',
        'iPhone/iPad',
        'Android phone',
        'Windows laptop/desktop',
        'Apple laptop/desktop',
        'Other'
    ],
    browserTypes: [
        'Google Chrome',
        'Mozilla Firefox',
        'Microsoft Edge',
        'Safari',
        'Opera',
        'Internet Explorer',
        'N/A'
    ]
};
class BugReportPage extends React.PureComponent {

    static propTypes = {
        onSubmitBugReport: T.func,
        onSubmitBugReportNoRedirect: T.func,
        onInvalidSubmit: T.func,
        onClickCloseAccount: T.func,
        openAlertDialog: T.func,
        location: T.object
    }

    constructor(props) {

        super(props);

        this.fields = [
            'bugDetails',
            'suggestedInterestCategory',
            'bugType'
        ];

        this.state = {
            bugType: '',
            bugDetails: null,
            suggestedInterestCategory: null,
            deviceType: '',
            browserType: '',
            bugDetailsErrorShow: null,
            suggestedInterestCategoryErrorShow: null,
            bugTypeErrorShow: false,
            submitting: false,
            pageIsDirty: false,
            pageHasErrors: false,
            isCloseMyAccount: false,
            isSuggestNewInterest: false,
            isSuggestNewConversationStarter: false,
            alertMessage: '',
            alertTitle: '',
            redirectUrl: '/app/welcome'
        };

        this.onSelectChange = this._onSelectChange.bind(this);
        this.onSelectBrowserChange = this._onSelectBrowserChange.bind(this);
        this.onSelectDeviceChange = this._onSelectDeviceChange.bind(this);
        this.onBugDetailsChange = this._onBugDetailsChange.bind(this);
        this.onSuggestedInterestCategoryChange = this._onSuggestedInterestCategoryChange.bind(this);
        this.error = this._error.bind(this);
        this.validate = this._validate.bind(this);
        this.submit = this._submit.bind(this);
        this.submitNoRedirect = this._submitNoRedirect.bind(this);
        this.stillExists = true;
        this.hasError = this._hasError.bind(this);
        this.getDescribedById = this._getDescribedById.bind(this);

        this.bugDetails = createRef();
    }

    dynamicConfirmFunction = () => {};
    dynamicCancelFunction = () => {};

    setDynamicConfirmFunction(newFunc) {

        this.dynamicConfirmFunction = newFunc;
    }
    setDynamicCancelFunction(newFunc) {

        this.dynamicCancelFunction = newFunc;
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        FixMuiMultilineAriaLabel(this.bugDetails.current,'bugDetails-input');

        if (this.props.location && this.props.location.state && this.props.location.state.isSuggestNewInterest){
            this.setState({
                bugType:4,
                isSuggestNewInterest:true,
                pageIsDirty:true
            });
        }

        if (this.props.location && this.props.location.state && this.props.location.state.comeFromEditPage){
            this.setState({
                redirectUrl:'/app/profile/edit/interests'
            });
        }
    }

    componentWillUnmount() {

        this.stillExists = false;
    }

    _onSelectChange(ev) {


        // value is index of element in internals.bugTypes
        const isSuggestNewInterest = ev.target.value === 4;
        const isSuggestNewConversationStarter = ev.target.value === 5;
        const isCloseMyAccount = ev.target.value === 6;

        this.setState({ bugType: ev.target.value,pageIsDirty:true,isCloseMyAccount,isSuggestNewInterest, isSuggestNewConversationStarter });
    }
    _onSelectBrowserChange(ev) {

        this.setState({ browserType: ev.target.value,pageIsDirty:true });
    }
    _onSelectDeviceChange(ev) {

        this.setState({ deviceType: ev.target.value,pageIsDirty:true });
    }

    _onBugDetailsChange(ev) {

        this.setState({ bugDetails: ev.target.value,pageIsDirty:true });
    }
    _onSuggestedInterestCategoryChange(ev) {

        this.setState({ suggestedInterestCategory: ev.target.value,pageIsDirty:true });
    }

    _validate(field) {

        const value = this.state[field];
        const { isCloseMyAccount, isSuggestNewInterest, isSuggestNewConversationStarter } = this.state;

        switch (field) {
            case 'bugDetails':
                if (!value || value === '' || value.length < 1) {
                    return isCloseMyAccount || isSuggestNewInterest || isSuggestNewConversationStarter ? (isCloseMyAccount ? 'Reason for closing account is required' : isSuggestNewConversationStarter ? 'Conversation starter text is required' : 'New Interest name is required') : 'Description is required';
                }

                break;
            case 'bugType':
                if (value === null || value === undefined || value === '' || value.length < 1) {
                    return 'This field is required';
                }

                break;
        }

        return null;
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? this.hasError(field) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            const hasErrors = this.fields.some(this.validate);

            if (!hasErrors) {
                this.setState({ pageHasErrors:false });
            }

            return null;
        }

        const error = this.validate(field);

        if (error !== null) {
            this.setState({ pageHasErrors:true });
            return <div id={this.id + '-' + field + '-error-message'} role='alert' aria-live='assertive'>{error}</div>;
        }

        const hasErrors = this.fields.some(this.validate);

        if (!hasErrors) {
            this.setState({ pageHasErrors:false });
        }

        return null;
    }

    _hasError(field) {

        if (this.validate(field) !== null && this.state.pageIsDirty) {
            this.setState({ pageHasErrors:true });
            return true;
        }

        const hasErrors = this.fields.some(this.validate);

        if (!hasErrors) {
            this.setState({ pageHasErrors:false });
        }

        return false;
    }

    _getDescribedById(field) {

        if (this.hasError(field)) {
            return (this.id + '-' + field + '-error-message');
        }

        return null;
    }

    _submit() {

        const { isCloseMyAccount } = this.state;

        if (!isCloseMyAccount) {
            gaTemplates.buttons('submit support', 'support: submit');

            const hasErrors = this.fields.some(this.validate);

            if (hasErrors) {
                this.setState({ pageHasErrors:true });
                // On submit attempt, all errors are fair game to display
                this.setState(this.fields.reduce((collector, field) => {

                    collector[`${field}ErrorShow`] = false;

                    if (this.validate(field)){
                        collector[`${field}ErrorShow`] = true;
                    }

                    return collector;
                }, {}));
                return this.props.onInvalidSubmit();
            }

            // No errors?  Submit the field/values

            const { bugType, bugDetails,browserType,deviceType, suggestedInterestCategory, isSuggestNewInterest } = this.state;
            this.setState({ submitting: true,pageIsDirty:false,pageHasErrors:false },
                () => {

                    if (!this.state.pageHasErrors){
                        this.props.onSubmitBugReport({
                            bugType: internals.bugTypes[bugType],
                            bugDetails: {
                                description: bugDetails,
                                suggestedInterestCategory: isSuggestNewInterest ? suggestedInterestCategory : '',
                                deviceType:internals.deviceTypes[deviceType] ? internals.deviceTypes[deviceType] : '',
                                browserType:internals.browserTypes[browserType] ? internals.browserTypes[browserType] : '',
                                role:'',
                                schoolName:''
                            }
                        },this.state.redirectUrl).then(() => {

                            if (this.stillExists) {
                                this.setState({ submitting: false,pageIsDirty:false,pageHasErrors:false });
                            }
                        });
                    }
                });
        }
        else {

            const hasErrors = this.fields.some(this.validate);

            if (hasErrors) {
                this.setState({ pageHasErrors:true });
                // On submit attempt, all errors are fair game to display
                this.setState(this.fields.reduce((collector, field) => {

                    collector[`${field}ErrorShow`] = false;

                    if (this.validate(field)){
                        collector[`${field}ErrorShow`] = true;
                    }

                    return collector;
                }, {}));
                return this.props.onInvalidSubmit();
            }

            this.setState({
                alertMessage:'Are you sure you want to close your account? By doing so, you will no longer be able to access the platform or your college\'s Nearpeer community, and all of your connections and conversations will be lost. If you need to re-open your account later, please contact us at administrator@nearpeer.net.',
                alertTitle:`Close account`
            });

            this.setDynamicConfirmFunction(() => {

                const { bugDetails } = this.state;
                this.props.onClickCloseAccount(
                    {
                        closeAccountReason: bugDetails
                    }
                );
            });
            this.setDynamicCancelFunction(() => {

            });
            this.props.openAlertDialog();
        }
    }
    _submitNoRedirect() {

        gaTemplates.buttons('submit support', 'support: submit');

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {
            this.setState({ pageHasErrors:true });
            // On submit attempt, all errors are fair game to display
            this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;

                if (this.validate(field)){
                    collector[`${field}ErrorShow`] = true;
                }


                return collector;
            }, {}));
            return this.props.onInvalidSubmit();
        }

        // No errors?  Submit the field/values

        const { bugType, bugDetails,browserType,deviceType, isSuggestNewInterest, suggestedInterestCategory } = this.state;

        this.setState({ submitting: true,pageIsDirty:false,pageHasErrors:false },
            () => {

                if (!this.state.pageHasErrors){
                    this.props.onSubmitBugReportNoRedirect({
                        bugType: internals.bugTypes[bugType],
                        bugDetails: {
                            description: bugDetails,
                            suggestedInterestCategory: isSuggestNewInterest ? suggestedInterestCategory : '',
                            deviceType:internals.deviceTypes[deviceType] ? internals.deviceTypes[deviceType] : '',
                            browserType:internals.browserTypes[browserType] ? internals.browserTypes[browserType] : '',
                            role:'',
                            schoolName:''
                        }
                    }).then(() => {

                        if (this.stillExists) {
                            this.setState({ submitting: false,pageIsDirty:false,pageHasErrors:false });
                        }
                    });
                }
            });
    }

    render() {

        const appVersion = process.env.APP_STORE_VERSION;

        const { isCloseMyAccount, isSuggestNewInterest,isSuggestNewConversationStarter } = this.state;
        const { categories: interestsCategories } = this.props;

        return <React.Fragment>
            <div>
                <BottomButtonContainer btnLabel='Submit' onBtnClick={this.submit} disabled={this.state.submitting}>
                    {isCloseMyAccount ?  <AlertDialog
                        title={this.state.alertTitle}
                        message={this.state.alertMessage}
                        confirmationCallback={() => {

                            this.dynamicConfirmFunction();
                        }}
                        cancelCallback={() => {

                            this.dynamicCancelFunction();
                        }}
                    /> : <SupportPagePreventNavigationDialog
                        // When should shouldBlockNavigation be invoked,
                        // simply passing a boolean
                        // (same as "when" prop of Prompt of React-Router)
                        saveFunction={this.submitNoRedirect}
                        pageHasErrors={this.state.pageHasErrors}
                        alertMsg={'Your Support form has not yet been submitted. Do you want to leave this page or stay?'}
                        when={this.state.pageIsDirty}
                        // Navigate function
                        // eslint-disable-next-line react/prop-types
                        navigate={(path,state) => this.props.history.push(path,state)}
                        // Use as "message" prop of Prompt of React-Router
                        shouldBlockNavigation={(location) => {

                            if (this.state.pageIsDirty || this.state.pageHasErrors) {
                                return true;
                            }

                            return false;
                        }}
                    />}
                    <div className={Classes.bugReportWrapper}>
                        <div className={Classes.bugReportInnerWrapper}>
                            <Typography
                                variant='h2'
                                style={{
                                    marginTop: 10,
                                    marginBottom: 16
                                }}
                            >
                                Support
                            </Typography>
                            <FormControl  classes={{ root:Classes.formControl }}>
                                <InputLabel error={this.state.bugTypeErrorShow}  id="bug-type-label">I want to...</InputLabel>
                                <SelectField
                                    className={Classes.fullWidth}
                                    labelId='bug-type-label'
                                    id={'bugTypeSelect'}
                                    MenuProps={{
                                        className: 'report-dropdown'
                                    }}
                                    inputProps={{
                                        'aria-labelledby': 'bug-type-label'
                                    }}
                                    value={this.state.bugType}
                                    error={this.state.bugTypeErrorShow}
                                    onBlur={this.showError('bugType')}
                                    onChange={this.onSelectChange}
                                >
                                    {internals.bugTypes.map((bugType, i) => {

                                        return <MenuItem value={i} key={i}>{bugType}</MenuItem>;
                                    })}
                                </SelectField>
                                {this.state.bugTypeErrorShow && <FormHelperText  error={this.state.bugTypeErrorShow}>{this.error('bugType')}</FormHelperText>}
                            </FormControl>
                            <FormControl classes={{ root:Classes.formControl }}>

                                <TextField
                                    className={Classes.fullWidth}
                                    placeholder={isCloseMyAccount || isSuggestNewInterest || isSuggestNewConversationStarter ? (isCloseMyAccount ? 'Add reason for closing account here' : (isSuggestNewConversationStarter ? 'Enter conversation starter text'  : 'Enter new Interest name')) : 'Add details here'}
                                    helperText={this.error('bugDetails')}
                                    error={this.state.bugDetailsErrorShow}
                                    /* inputProps={{
                                        'aria-labelledby': 'bugDetails-label'
                                    }}*/
                                    InputProps={{
                                        'aria-labelledby': 'bugDetails-label'
                                    }}

                                    onBlur={this.showError('bugDetails')}
                                    aria-invalid={this.hasError('bugDetails')}
                                    label={isCloseMyAccount || isSuggestNewInterest || isSuggestNewConversationStarter ? (isCloseMyAccount ? '*Reason for closing account?' : (isSuggestNewConversationStarter ? '*New Conversation Starter' : '*New Interest')) : 'Description'}
                                    multiline={true}
                                    id={'bugDetails'}
                                    onChange={this.onBugDetailsChange}
                                    ref={this.bugDetails}
                                    //minRows={2}
                                />
                            </FormControl>
                            {!isCloseMyAccount && isSuggestNewInterest && <FormControl  classes={{ root:Classes.formControl }}>
                                <InputLabel error={this.state.suggestedInterestCategoryErrorShow}  id="suggestedInterestCategory-label">New Interest Category</InputLabel>
                                <SelectField
                                    className={Classes.fullWidth}
                                    labelId='suggestedInterestCategory-label'
                                    id={'n'}
                                    MenuProps={{
                                        className: 'report-dropdown'
                                    }}
                                    inputProps={{
                                        'aria-labelledby': 'bug-type-label'
                                    }}
                                    value={this.state.suggestedInterestCategory}
                                    error={this.state.suggestedInterestCategoryErrorShow}
                                    onBlur={this.showError('suggestedInterestCategory')}
                                    onChange={this.onSuggestedInterestCategoryChange}
                                >
                                    <MenuItem value={null}>Select a Category…</MenuItem>
                                    {interestsCategories.map((interestCategory, i) => {

                                        return <MenuItem value={interestCategory.name} key={i}>{interestCategory.name}</MenuItem>;
                                    })}
                                </SelectField>
                                {this.state.suggestedInterestCategoryErrorShow && <FormHelperText  error={this.state.suggestedInterestCategoryErrorShow}>{this.error('suggestedInterestCategory')}</FormHelperText>}
                            </FormControl>}

                            {!isCloseMyAccount && !isSuggestNewInterest && !isSuggestNewConversationStarter && <FormControl classes={{ root: Classes.formControl }}>
                                <InputLabel id="device-type-label">Device type</InputLabel>
                                <SelectField
                                    className={Classes.fullWidth}
                                    labelId='device-type-label'
                                    MenuProps={{
                                        className: 'device-dropdown'
                                    }}
                                    inputProps={{
                                        'aria-labelledby': 'device-type-label'
                                    }}
                                    value={this.state.deviceType}
                                    onChange={this.onSelectDeviceChange}
                                >
                                    {internals.deviceTypes.map((deviceType, i) => {

                                        return <MenuItem value={i} key={i}>{deviceType}</MenuItem>;
                                    })}
                                </SelectField>
                            </FormControl>
                            }
                            {!isCloseMyAccount && !isSuggestNewInterest && !isSuggestNewConversationStarter &&  <FormControl classes={{ root:Classes.formControl }}>
                                <InputLabel id="browser-type-label">Browser type</InputLabel>
                                <SelectField
                                    className={Classes.fullWidth}
                                    labelId    ='browser-type-label'
                                    MenuProps={{
                                        className: 'browser-dropdown'
                                    }}
                                    inputProps={{
                                        'aria-labelledby': 'browser-type-label'
                                    }}
                                    value={this.state.browserType}
                                    onChange={this.onSelectBrowserChange}
                                >
                                    {internals.browserTypes.map((browserType, i) => {

                                        return <MenuItem value={i} key={i} >{browserType}</MenuItem>;
                                    })}
                                </SelectField>
                            </FormControl>
                            }
                        </div>
                    </div>
                </BottomButtonContainer>
            </div>
            <div className={Classes.appVersionWrapper}>
                {`Nearpeer v${appVersion} `}&copy;{new Date().getFullYear()}
            </div>
        </React.Fragment>;
    }
}

BugReportPage.propTypes = {
    categories: T.array.isRequired
};
module.exports = BugReportPage;
