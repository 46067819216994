// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___a4atg{background-size:cover!important;height:100%;width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Image/styles.scss"],"names":[],"mappings":"AAAA,yBAGE,+BAAiB,CADjB,WAAY,CADZ,UAEiC","sourcesContent":[".wrapper{\n  width: 100%;\n  height: 100%;\n  background-size: cover !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___a4atg`
};
export default ___CSS_LOADER_EXPORT___;
