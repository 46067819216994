const React = require('react');
const { default: Styled } = require('styled-components');

const UserInputs = require('components/UserInputs');
const BottomButtonContainer = require('components/BottomButtonContainer');
const PreventNavigationDialog = require('components/PreventNavigationDialog');
const { gaTemplates } = require('utils/react-ga');
const AppLayout = require('layouts/AppLayout');

const internals = {};

module.exports = class EditPage extends UserInputs {

    onBottomButtonClick = () => {

        gaTemplates.buttons('save my profile', 'my profile: save my profile request');
        this.submit({ shouldRedirect: true });
    }

    onBottomButtonClickNoRedirect = () => {

        gaTemplates.buttons('save my profile', 'my profile: save my profile request');
        this.submit({ shouldRedirect: false });
    }

    componentDidMount() {

        super.componentDidMount();
        const scrollContainer = AppLayout.getScrollContainer();

        if (scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }

    render() {

        const { Wrapper, FieldsWrapper } = internals;
        const { history } = this.props;
        return (
            <Wrapper>
                <BottomButtonContainer
                    btnLabel='Save'
                    onBtnClick={this.onBottomButtonClick}
                    disabled={this.state.submitting || this.state.uploadingProfilePic}
                >
                    <PreventNavigationDialog
                        // When should shouldBlockNavigation be invoked,
                        // simply passing a boolean
                        // (same as "when" prop of Prompt of React-Router)
                        saveFunction={this.onBottomButtonClickNoRedirect}
                        alertMsg={'You have made changes.\n Do you want to save or discard them?'}
                        when={this.state.pageIsBlocking}
                        // Navigate function
                        navigate={(path,state) => history.push(path,state)}
                        // Use as "message" prop of Prompt of React-Router
                        shouldBlockNavigation={(location) => {

                            if (this.state.pageIsBlocking || this.state.pageHasErrors) {
                                return true;
                            }

                            return false;
                        }}
                        pageHasErrors={this.state.pageHasErrors}
                    />
                    {this.renderFields({ fieldsWrapper: FieldsWrapper })}
                </BottomButtonContainer>
            </Wrapper>
        );
    }
};

internals.Wrapper = Styled.div`
    overflow: hidden;
    height: 100%;
`;

internals.FieldsWrapper = Styled.div`
    padding: 1.5em;
`;
