const checkStringMultipleBadWords = (str, BadWords) => {

    const foundBadWords = [];
    if (!str) {
        return foundBadWords;
    }

    let i = 0;
    let j = 0;

    str = clearTextCompletely(str);

    // convert into array and remove white space
    // add default returned value for some cases (e.g. "." will returns null)
    const strs = str.match(/[^ ]+/g) || [];
    while (i <= BadWords.length - 1) {
        if (strs.includes(BadWords[i].toLowerCase())) {
            foundBadWords.push(BadWords[i]);
        }

        i++;
    }

    while (j <= BadWords.length - 1) {

        const multipleSizeWords = BadWords[j].split(' ');

        if (multipleSizeWords.length > 1) {
            if (str.includes(BadWords[j].toLowerCase())) {
                foundBadWords.push(BadWords[j]);
            }
        }

        j++;
    }

    return foundBadWords;
};

const removePunctuations = (text) => {

    const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/gi;

    return text.replace(punctuationRegex, '');
};

const removeNewLines = (text) => {

    const newLineRegex = /[\r\n]+/gim;

    return text.replace(newLineRegex, ' ');
};

const removeMultipleSpaces = (text) => {

    const multipleSpaceRegex = /\s+/gim;

    return text.replace(multipleSpaceRegex, ' ');
};

const clearTextCompletely = (text) => {

    let clearText = removePunctuations(text);
    clearText = removeNewLines(clearText);
    clearText = removeMultipleSpaces(clearText);

    return clearText.toLowerCase();
};

module.exports = {
    checkStringMultipleBadWords,
    removePunctuations,
    removeNewLines,
    removeMultipleSpaces,
    clearTextCompletely
};
