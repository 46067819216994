const { useEffect,useRef } = require('react');

const usePrevious = (value) => {

    const ref = useRef();
    useEffect(() => {

        ref.current = value;
    });

    return ref.current;
};

exports.usePrevious = usePrevious;
