const { connect } = require('react-redux');
const { push: Push } = require('connected-react-router');
const { context } = require('app-context');
const Homepage = require('../components/Homepage');

const selectors = context.selectors.all;
const actions   = context.actions;

module.exports = connect(
    (state) => ({
        isAuthenticated: selectors.getIsAuthenticated(state),
        platformName: selectors.getPlatformName(state)
    }), {
        onLogout: actions.auth.logout,
        navigate: Push
    }
)(Homepage);
