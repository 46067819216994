
const { context } = require('../app-context');

const selectors = context.selectors.all;
const { setUserId, unsetUserId } = require('../utils/react-ga');

module.exports = (store) => {

    store.subscribe(() => {

        const currentUser = selectors.getCurrentUser(store.getState()).user();

        if (currentUser) {
            return setUserId(currentUser.id);
        }

        unsetUserId();
    });
};
