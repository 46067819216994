// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___oegKd{align-items:center;background-color:#e0e0e0;border-radius:8px;display:flex;height:2rem;justify-content:center;width:2rem}.styles__container___oegKd:not(:last-of-type){margin-right:.5rem}.styles__container___oegKd img{max-height:100%}`, "",{"version":3,"sources":["webpack://./src/components/PassionInterestItem/styles.scss"],"names":[],"mappings":"AAAA,2BAGE,kBAAmB,CAInB,wBAAyB,CACzB,iBAAe,CANf,YAAa,CAGb,WAAY,CADZ,sBAAuB,CAEvB,UAEkB,CARpB,8CAUI,kBAAmB,CAVvB,+BAaI,eAAgB","sourcesContent":[".container{\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 2rem;\n  width: 2rem;\n  background-color: #e0e0e0;\n  border-radius: 8px;\n  &:not(:last-of-type){\n    margin-right: .5rem;\n  }\n  img{\n    max-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___oegKd`
};
export default ___CSS_LOADER_EXPORT___;
