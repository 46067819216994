const { connect } = require('react-redux');
const { context } = require('app-context');
const UserInfo = require('../components/UserInfo');
const { safeWarning } = require('assertions-simplified');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;

module.exports = connect(
    (state) => {

        const { schoolId } = selectors.getSignupContext(state);

        return {
            isAuthenticated: selectors.getIsAuthenticated(state),
            categories: selectors.getCategories(state),
            interests: selectors.getInterests(state),
            interestsLoading: selectors.getIsLoadingInterests(state),
            officesLoading: selectors.getIsLoadingOffices(state),
            yearsHiredLoading: selectors.getIsLoadingYearsHired(state),
            onCampusHousingList: selectors.getOnCampusHousingList(state, schoolId) || [],
            offCampusHousingList: selectors.getOffCampusHousingList(state, schoolId) || [],
            transfers: selectors.getBasicTransfers_bySchoolId(state, schoolId),
            signupDetails: selectors.getSignupDetailsForStep3(state),
            majors: selectors.getMajorsList_bySchoolId(state, schoolId),
            departments: selectors.getDepartmentsList_bySchoolId(state, schoolId),
            yearsHiredList: selectors.getAllYearsHired(state),
            officesList: selectors.getAllOffices(state),
            incomingClassList: selectors.getIncomingClassList_bySchoolId(state, schoolId),
            graduatingClassList: selectors.getGraduatingClassList_bySchoolId(state, schoolId),
            conditionalDisplayFields: selectors.getConditionalDisplayFields_bySchoolId(state, schoolId),
            badges: selectors.getBadgesForCurrentUser(state),
            isStudentRoleGroup: selectors.isStudentRoleGroup(state,selectors.getCurrentUserId(state)),
            isParent: selectors.isParentPermission(state),
            rolePermissions: selectors.getSignupStep3RolePermissions(state),
            role: selectors.getSignupStep3Role(state),
            schoolRoles: selectors.getSchoolRoles(state),
            isCompany: selectors.getSchoolIsCompany(state),
            isCommunity: selectors.getSchoolIsCommunity(state),
            isSchoolOnline: selectors.getSchoolIsOnline(state),
            signupContext: selectors.getSignupContext(state),
            fieldsPlaceholders: selectors.getFieldsPlaceholders(state,schoolId),
            useProfanityFilter: selectors.getShouldUseProfanityFilter(state,schoolId)
        };
    }, {
        onLogout: actions.auth.logout,
        onGoBack: actions.signup.step3.goBack,
        onSubmit: actions.signup.step3.complete,
        onSubmitNoRedirect: (signupDetails) => (dispatch) => { // eslint-disable-line

            const profileDiff = selectors.getUserProfileDiff_forUpdateProfile(redux.getState(), signupDetails);

            return dispatch(actions.profileManagement.updateProfile(profileDiff))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile failed');
                }

                dispatch(actions.alerts.notification.push({
                    message: 'Profile updated'
                }));
            })
            .catch(safeWarning);
        },
        onInvalidSubmit: () => actions.alerts.notification.push({ message: 'Please fix invalid form fields' }),
        saveProfileProgress: (obj) => (dispatch, getState) => { // eslint-disable-line

            const { verificationToken } = selectors.getSignupContext(getState());

            if (verificationToken) {
                dispatch(actions.signup.step3.updateSignupProgress({ verificationToken, signupDetails: obj }));
            }
        },
        saveProfilePicture: (obj) => async (dispatch, getState) => { // eslint-disable-line

            const { verificationToken } = selectors.getSignupContext(getState());

            return dispatch(actions.signup.step3.updateSignupProgressProfilePic({ verificationToken, pictureDetails: obj }))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile picture failed');
                }

                dispatch(actions.alerts.notification.push({
                    message: 'Profile picture updated'
                }));

                return success;
            })
            .catch(safeWarning);
        },
        savePreferencesProgress: (prefs) => (dispatch, getState) => { // eslint-disable-line

            const { verificationToken } = selectors.getSignupContext(getState());
            dispatch(actions.signup.step3.updatePreferencesPresignup({ verificationToken, prefs }));
        }
    }
)(UserInfo);
