// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__emptyPicIcon___Zz62Q{color:#bdbdbd;height:100%!important;width:100%!important}`, "",{"version":3,"sources":["webpack://./src/components/NoUserProfilePic/styles.scss"],"names":[],"mappings":"AAAA,8BAIE,aAAO,CADP,qBAAuB,CADvB,oBAEc","sourcesContent":[".emptyPicIcon {\n\n  width: 100% !important;\n  height: 100% !important;\n  color: #bdbdbd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyPicIcon": `styles__emptyPicIcon___Zz62Q`
};
export default ___CSS_LOADER_EXPORT___;
