// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__badgeContainer___LBvoj{display:inline-block;height:50px;line-height:26px;margin-top:14px;position:relative;width:50px}.styles__badgeContainer___LBvoj img{height:100%;width:100%}.styles__badgeContainer___LBvoj .styles__achievementContent___VsWmb{left:50%;position:absolute;top:50%;transform:translate(-50%,-50%)}`, "",{"version":3,"sources":["webpack://./src/components/UserAchievements/styles.scss"],"names":[],"mappings":"AACA,gCAMI,oBAAqB,CAHrB,WAAY,CACZ,gBAAiB,CAHjB,eAAgB,CAMhB,iBAAU,CALV,UAKkB,CAPtB,oCAWQ,WAAQ,CADR,UACY,CAXpB,oEAgBQ,QAAS,CADT,iBAAkB,CAElB,OAAQ,CACR,8BAAgC","sourcesContent":["\n.badgeContainer {\n    margin-top: 14px;\n    width: 50px;\n    height: 50px;\n    line-height: 26px;\n    position: relative;\n    display: inline-block;\n    position: relative;\n\n    img {\n        width: 100%;\n        height: 100%;\n    }\n\n    .achievementContent {\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeContainer": `styles__badgeContainer___LBvoj`,
	"achievementContent": `styles__achievementContent___VsWmb`
};
export default ___CSS_LOADER_EXPORT___;
