const { connect } = require('react-redux');
const { context } = require('app-context');
const ClassDetailPage = require('../components/ClassDetailPage');
const { gaTemplates } = require('utils/react-ga');

const selectors = context.selectors.all;
const actions   = context.actions;

const internals = {};

module.exports = connect(
    (state, { match: { params } }) => ({
        classDetails: selectors.getClass_forClassDetail_withUserSearchResults(state, params.id),
        classSearchCriteria: selectors.getCriteria_forClassSearch(state, params.id),
        showLoadMore: selectors.getShowLoadMore_forClassSearch(state, params.id),
        showJoin: selectors.getCanJoinClass(state, params.id),
        classSearchText: selectors.getClassSearchTextById(state, params.id),
        badges: selectors.getAllBadges(state),
        appContextFilter: selectors.getAppContextFilter(state),
        activeFilterRoleGroup: selectors.getActiveFilterRoleGroup_byName(state,selectors.getAppContextFilter(state)),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        role: selectors.getCurrentUserRole(state),
        isParent: selectors.isParentPermission(state)
    }), {
        onClickJoin: actions.classes.join,
        onClickLeave: actions.classes.leave,
        onClickConnect: actions.communication.requestPeer,
        setClassSearchText: actions.classes.setSearchText,
        openAlertDialog: actions.app.openAlertDialog,
        onClickShowToast: (msg) => actions.alerts.notification.push({ message: msg }),
        onClickLoadMore: (classId) => actions.dataFetching.fetchClassUserSearchResults.nextPage({ classId }),
        onSubmitSearch: (criteria) => (dispatch, getState) => { // eslint-disable-line hapi/no-arrowception, hapi/hapi-scope-start

            const filteredCriteria = internals.filterOutEmpty(criteria);
            const searchKeys = Object.keys(filteredCriteria);
            const onlyContainsClassId = searchKeys.length === 1 && typeof filteredCriteria.classId !== 'undefined';

            gaTemplates.buttons('submit peers search', `class details: search submit ${searchKeys.length ? searchKeys : 'empty'}`);

            if (onlyContainsClassId) {
                return dispatch(actions.dataFetching.fetchClassUserSearchResults.firstPage({
                    classId: filteredCriteria.classId,
                    allByMatches: true
                }));
            }

            const { interests, ...others } = filteredCriteria;

            const search = { ...others };

            if (interests) {
                search.interests = interests.map((x) => x.id);
            }

            return dispatch(actions.dataFetching.fetchClassUserSearchResults.firstPage(search));
        },
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        rememberScroll: () => (dispatch) => {

            return dispatch(actions.app.rememberScroll({ type: 'group' }));
        },
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        restoreScroll: () => (dispatch) => {

            return dispatch(actions.app.restoreScroll({ type: 'group' }));
        }
    }
)(ClassDetailPage);

internals.filterOutEmpty = (obj) => {

    const { isBool } = internals;

    return Object.keys(obj).filter((key) => {

        const value = obj[key];

        if (Array.isArray(value)) {
            return !!value.length;
        }

        return isBool(value) || !!value;
    })
    .reduce((collector, key) => {

        collector[key] = obj[key];
        return collector;
    }, {});
};

internals.isBool = (maybeBool) => {

    if (String(maybeBool) === 'true' || String(maybeBool) === 'false') {
        return true;
    }

    return false;
};
