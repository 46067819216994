const React = require('react');
const { default: styled, withTheme }  = require('styled-components');
const UniqueId = require('lodash/uniqueId');
const PropTypes = require('prop-types');
const { matchSorter } = require('match-sorter');
const { default: Box } = require('@mui/material/Box');
const { default: TextField } = require('@mui/material/TextField');
const { default: Typography } = require('@mui/material/Typography');
const { default: Button } = require('@mui/material/Button');
const { default: Autocomplete } = require('@mui/material/Autocomplete');
const { NavLink } = require('react-router-dom');
const { default:clsx } = require('clsx');
const { default: Classes } = require('./styles.scss');
const Fonts = require('styles/fonts.json');
const Colors = require('styles/colors.json');
const IsEmail = require('utils/is-email');
const { gaTemplates } = require('utils/react-ga');
const Logomark = require('static/nearpeer_n_white.svg');
const NearpeerMobile = require('static/nearpeer-mobile.png');
const NearpeerMobileBusiness = require('static/nearpeer-mobile-business-no-bg.png');
// iOS-only image is for app store compliance
const NearpeerMobileiOS = require('static/nearpeer-mobile-ios.png');
const NearpeerMobileBusinessiOS = require('static/nearpeer-mobile-business-ios-new.png');
const PlayStore = require('static/google_play.svg');
const AppStore = require('static/app_store.svg');
const Snackbar = require('containers/Snackbar');
const ScrollContainerLayout = require('layouts/ScrollContainerLayout');
const Footer = require('components/Footer');
const PageTitleByPath = require('utils/pageTitleByPath');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const internals = {};

class SignupEntry extends React.PureComponent {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        platformName: PropTypes.string.isRequired,
        onInvalidSubmit: PropTypes.func.isRequired,
        schools: PropTypes.array.isRequired,
        signupDetails: PropTypes.shape({
            email: PropTypes.string,
            schoolId: PropTypes.number
        }),
        isCompany: PropTypes.bool,
        isCommunity: PropTypes.bool,
        showSchoolList: PropTypes.bool,
        text: PropTypes.string,
        secondaryText: PropTypes.string,
        tertiaryText: PropTypes.node,
        customGraphic: PropTypes.node,
        customError: PropTypes.node,
        useWhiteLanding: PropTypes.bool,
        useWideLogo: PropTypes.bool,
        useExtraWideLogo: PropTypes.bool,
        footer: PropTypes.any,
        logo: PropTypes.string,
        match: PropTypes.shape({
            params: PropTypes.shape({
                slug: PropTypes.string
            })
        }),
        isParentView: PropTypes.bool,
        theme: PropTypes.object,
        push: PropTypes.func.isRequired
    };

    static defaultProps = {
        isParentView: false
    }

    constructor(props) {

        super(props);

        this.id = UniqueId('entry-');

        this.fields = ['email', 'schoolId'];

        this.state = {
            email: props.signupDetails.email || '',
            schoolId: props.signupDetails.schoolId || null,
            emailErrorShow: false,
            schoolIdErrorShow: false,
            schoolSearchText: !Array.isArray(props.schools)
                ? ''
                : (
                    props.schools.find(
                        (school) => school.id === props.signupDetails.schoolId
                    ) || {}
                ).name || ''
        };

        this.emailChanged = this._emailChanged.bind(this);
        this.setSchoolSearchText = this._setSchoolSearchText.bind(this);
        this.setSchoolId = this._setSchoolId.bind(this);
        this.error = this._error.bind(this);
        this.hasError = this._hasError.bind(this);
        this.getDescribedById = this._getDescribedById.bind(this);
        this.validate = this._validate.bind(this);
    }

    componentDidMount() {

        document.body.style.backgroundColor = this.props.theme.palette.primary.main;
    }

    componentWillUnmount() {

        document.body.style.backgroundColor = 'unset';
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.signupDetails && nextProps.signupDetails.schoolId  && nextProps.signupDetails !== this.props.signupDetails) {
            this.setState({
                schoolId: nextProps.signupDetails.schoolId
            });
        }
    }

    _setSchoolSearchText(ev) {

        this.setState({ schoolSearchText: (ev && ev.target.value) ?  ev.target.value : '' });
    }

    _emailChanged(ev) {

        this.setState({ email: ev.target.value });
    }

    _setSchoolId(event, school) {

        school = school || { id: null, name: null };

        const { id, name } = school;

        this.setState({
            schoolId: id,
            schoolSearchText: name,
            schoolIdError: null
        });
    }

    submitOrError = async () => {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {
        // On submit attempt, all errors are fair game to display
            this.setState(
                this.fields.reduce((collector, field) => {

                    collector[`${field}ErrorShow`] = false;
                    if (this.validate(field)){
                        collector[`${field}ErrorShow`] = true;
                    }

                    return collector;
                }, {})
            );

            AnimatedFocusIndicator.onBlurHandler();

            return this.props.onInvalidSubmit(this.state);
        }

        const slug = this.props.match.params.slug || '';

        gaTemplates.buttons(
            'create account',
            slug
                ? `landing page ${slug}: create account`
                : 'landing page: create account'
        );

        const res = await this.props.onSubmit(this.state);

        if (!res) {
            this.setState({
                emailErrorShow: true
            });
        }
    }

    showError(field, show) {

        show = typeof show === 'undefined' ? true : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }

        const error = this.validate(field);

        if (error !== null) {
            return (
                <div
                    id={this.id + '-' + field + '-error-message'}
                    role='alert'
                    aria-live='assertive'
                >
                    {error}
                </div>
            );
        }

        return null;
    }

    _hasError(field) {

        if (this.validate(field) !== null) {
            return true;
        }

        return false;
    }

    _getDescribedById(field) {

        if (this.hasError(field)) {
            return this.id + '-' + field + '-error-message';
        }

        return null;
    }

    _validate(field) {

        const isTeamsApp = process.env.APP_FLAVOUR === 'teams';
        const value = this.state[field];

        switch (field) {
            case 'email':
                if (!value) {
                    return 'Email is required';
                }

                if (!IsEmail(value)) {
                    return 'Invalid email address';
                }

                break;

            case 'schoolId':
                if (!value || !this.state.schoolSearchText) {
                    return   `${isTeamsApp ? 'Team' : 'College'} is required`;
                }

                break;
        }

        return null;
    }

    render() {

        const {
            styles,
            StyledTextField,
            StyledButton,
            SchoolTip,
            Link
        } = internals;

        const {
            showSchoolList,
            text,
            secondaryText,
            tertiaryText,
            useWhiteLanding,
            useWideLogo,
            useExtraWideLogo,
            footer,
            logo,
            match: { params },
            customGraphic,
            customError,
            schools,
            isParentView,
            isCompany,
            isCommunity
        } = this.props;

        const { pathname: path } = window.location;

        const pageTitle = PageTitleByPath(path);

        const isTeamsApp = process.env.APP_FLAVOUR === 'teams';
        const nearpeerLogo = require('static/logos/nearpeer_logo_white.png');

        const devicePlatform = this.props.platformName && this.props.platformName;
        const device = /(android)/i.test(navigator.userAgent)
            ? 'android'
            : ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || devicePlatform === 'iPhone' || devicePlatform === 'iPad' || devicePlatform === 'iPhone Simulator' || devicePlatform === 'iPad Simulator' || devicePlatform === 'iOS')
                ? 'ios'
                : 'other';

        const filteredSchools = schools ? schools.filter((school) => {

            return isTeamsApp ? school.isCompany : !school.isCompany;
        }) : [];

        // Empty string is falsy for ternaries
        const slug = params.slug || '';

        const isAsga = slug === 'asga';
        const isUmaine = slug === 'umaine';
        const isForbiddenEmail = this.state.email.includes('@txstate') ||
            this.state.email.includes('@umsystem') ||
            this.state.email.includes('@fresnostate');

        const showLoyolaHintText = filteredSchools &&
            this.state.schoolSearchText &&
            this.state.schoolSearchText.toLowerCase().includes('loyola');

        const filterOptions = (options, { inputValue }) => {

            return matchSorter(options, inputValue, { keys: ['name'] });
        };

        if (isUmaine) {
            require('../../../../scripts/umaineTracker');
        }

        return (
            <>
                <ScrollContainerLayout>
                    <Box sx={{
                        backgroundColor: useWhiteLanding ? '#ffffff' : 'primary.main'
                    }} component='div' className={`${Classes.bodyContainer}`}>
                        <div className={Classes.homeMain} role='main'>
                            {!logo && (
                                <div className={Classes.logoContainer}>
                                    <div className={Classes.logomarkContainer}>
                                        <img src={Logomark} alt='' className={Classes.logomark} />
                                        <div className={`${Classes.tm} ${useWhiteLanding ? Classes.csun : ''}`}>™</div>
                                    </div>
                                    <h1 aria-label={pageTitle} className={`${Classes.header} ${useWhiteLanding ? Classes.csun : ''}`}>Nearpeer</h1>
                                </div>
                            )}

                            {logo && !useWideLogo && (
                                <div className={`${Classes.logosWrapper} ${useWhiteLanding ? Classes.csun : ''}`}>
                                    <div className={Classes.nearpeerLogo}>
                                        <img className={Classes.logo} alt={slug} src={nearpeerLogo} />
                                    </div>
                                    <div className={Classes.schoolLogo}>
                                        <img className={Classes.logo} alt={slug} src={logo} />
                                    </div>
                                </div>
                            )}

                            {logo && useWideLogo && (
                                <div className={`${Classes.logosWrapper} ${useWideLogo ? (useExtraWideLogo ? Classes.useExtraWideLogo : Classes.wideLogo) : ''}`}>
                                    <div className={`${Classes.schoolLogo} ${Classes.wideLogo}`}>
                                        <img className={Classes.logo} alt={slug} src={logo} />
                                    </div>
                                </div>
                            )}

                            {text && <div className={`${Classes.subtitle} ${useWhiteLanding ? Classes.csun : ''} ${isAsga ? Classes.asga : ''}`}>{text}</div>}

                            <div className={clsx(Classes.homeContent, (isCompany || isCommunity) && Classes.alignMiddle)}>
                                <div className={Classes.mobileScreensContainer}>
                                    <img
                                        className={Classes.mobileScreens}
                                        src={(isCompany || isCommunity || isTeamsApp) ? (device === 'ios' ? NearpeerMobileBusinessiOS : (customGraphic ? customGraphic : NearpeerMobileBusiness)) : (device === 'ios' ? NearpeerMobileiOS : customGraphic ? customGraphic : NearpeerMobile)}
                                        alt='Nearpeer'
                                    />
                                </div>
                                <div className={Classes.homeActions}>
                                    {!window.cordova && (
                                        <div className={Classes.getApps}>
                                            <div className={`${Classes.actionText} ${useWhiteLanding ? Classes.csun : ''}`}>Get the Nearpeer app{isParentView && ' (will look like the student version, that\'s OK)'}:</div>
                                            {
                                                // Only show Play Store link on Android devices, and only App Store link on iOS devices.
                                                // Also, incorporate a slight style change if only one button is shown (Classes.onPhone)
                                            }
                                            <div
                                                className={`${Classes.getAppsButtons} ${useWhiteLanding ? Classes.csun : ''} ${
                                                    device !== 'other' && Classes.onPhone
                                                }`}
                                            >
                                                {device !== 'android' && (
                                                    (isTeamsApp || isCompany) ? <a
                                                        onClick={() => gaTemplates.buttons('signup', slug ? `signup ${slug}: get teams ios app` : 'signup: get teams ios app')}
                                                        className={Classes.getAppsButton}
                                                        href='https://itunes.apple.com/us/app/nearpeer-for-teams/id6445984458'
                                                        rel='noreferrer'
                                                        target={'_blank'}
                                                        data-focus-outline='radius:5,padding:2'
                                                    >
                                                        <img src={AppStore} alt='AppStore' />
                                                    </a> : <a
                                                        onClick={() => gaTemplates.buttons('signup', slug ? `signup ${slug}: get ios app` : 'signup: get ios app')}
                                                        className={Classes.getAppsButton}
                                                        href='https://itunes.apple.com/us/app/nearpeer-for-college-students/id1300243604'
                                                        rel='noreferrer'
                                                        target={'_blank'}
                                                        data-focus-outline='radius:5,padding:2'
                                                    >
                                                        <img src={AppStore} alt='AppStore' />
                                                    </a>
                                                )}
                                                {device !== 'ios' && (
                                                    (isTeamsApp || isCompany) ? <a
                                                        onClick={() => gaTemplates.buttons('signup', slug ? `signup ${slug}: get android app` : 'signup: get android app')}
                                                        className={Classes.getAppsButton}
                                                        href='https://play.google.com/store/apps/details?id=net.nearpeer.app_business'
                                                        rel='noreferrer'
                                                        target={'_blank'}
                                                        data-focus-outline='radius:5,padding:2'
                                                    >
                                                        <img src={PlayStore} alt='PlayStore' />
                                                    </a> : <a
                                                        onClick={() => gaTemplates.buttons('signup', slug ? `signup ${slug}: get android app` : 'signup: get android app')}
                                                        className={Classes.getAppsButton}
                                                        href='https://play.google.com/store/apps/details?id=net.nearpeer.app'
                                                        rel='noreferrer'
                                                        target={'_blank'}
                                                        data-focus-outline='radius:5,padding:2'
                                                    >
                                                        <img src={PlayStore} alt='PlayStore' />
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className={Classes.signupContainer}>
                                        <div>
                                            {secondaryText && (
                                                <div
                                                    style={{ paddingBottom: 0 }}
                                                    className={`${Classes.actionText } ${useWhiteLanding ? Classes.csun : ''} `}
                                                >
                                                    {secondaryText}
                                                </div>
                                            )}
                                            <br />
                                            <StyledTextField
                                                type='email'
                                                label='Email'
                                                id={'email-input'}
                                                onBlur={this.showError('email')}
                                                onFocus={this.showError('email', false)}
                                                onChange={this.emailChanged}
                                                style={{ marginBottom: 10 }}
                                                value={this.state.email}
                                                error={Boolean(this.error('email'))}
                                                helperText={this.error('email')}
                                                aria-invalid={this.hasError('email')}
                                                aria-describedby={this.error('email') ? this.getDescribedById('email') : null}
                                                fullWidth
                                                InputLabelProps={{ style: styles.floatingLabel(useWhiteLanding) }}
                                                inputColor={useWhiteLanding && '#333333'}
                                                highlightColor={useWhiteLanding && '#333333'}
                                                errorColor={useWhiteLanding &&  Colors.muiPrimary}
                                            />
                                            {/* TODO refactor this to be part of config in 'src/utils/customSignups.js' */}
                                            {this.state.email.includes('@umsystem') && (
                                                <SchoolTip>
                                                    Use a valid @student.umkc.edu or personal email on file.
                                                    Using @umsystem.edu address is not permitted.
                                                </SchoolTip>
                                            )}
                                            {this.state.email.includes('@txstate') && (
                                                <SchoolTip>
                                                    Use a valid personal email on file with TXST. Using
                                                    @txstate.edu address is not permitted by TXST.
                                                </SchoolTip>
                                            )}
                                            {this.state.email.includes('@fresnostate') && (
                                                <SchoolTip>
                                                    Use a valid @mail.fresnostate.edu email. Using
                                                    @fresnostate.edu is not permitted.
                                                </SchoolTip>
                                            )}
                                            {this.state.emailErrorShow && customError && (
                                                <SchoolTip>
                                                    {customError}
                                                </SchoolTip>
                                            )}
                                            {showLoyolaHintText && (
                                                <SchoolTip>
                                                    Students should sign up with a @my.loyno.edu email
                                                </SchoolTip>
                                            )}

                                            {showSchoolList && <br />}
                                            {showSchoolList && (
                                                <Autocomplete
                                                    options={filteredSchools}
                                                    inputValue={this.state.schoolSearchText}
                                                    onInputChange={this.setSchoolSearchText}
                                                    value={this.schoolId}
                                                    onChange={this.setSchoolId}
                                                    getOptionLabel={({ name }) => name}
                                                    filterOptions={filterOptions}
                                                    renderInput={(args) => (

                                                        <StyledTextField
                                                            {...args}
                                                            onBlur={this.showError('schoolId')}
                                                            onFocus={this.showError('schoolId', false)}
                                                            error={Boolean(this.error('schoolId'))}
                                                            helperText={this.error('schoolId')}
                                                            InputProps={{
                                                                ...args.InputProps,
                                                                endAdornment: null
                                                            }}
                                                            InputLabelProps={{
                                                                style: styles.floatingLabel(useWhiteLanding)
                                                            }}
                                                            label={`${isTeamsApp ? 'Team' : 'College'} - Start typing to view list`}
                                                        />
                                                    )}
                                                />
                                            )}
                                            {showSchoolList && <br />}

                                            <StyledButton
                                                color='primary'
                                                onClick={this.submitOrError}
                                                disabled={isForbiddenEmail}
                                                backgroundColor={useWhiteLanding && '#000000'}
                                                textColor={useWhiteLanding && '#ffffff'}
                                                style={{
                                                    marginTop: 20
                                                }}
                                                data-focus-outline='radius:40,padding:4'
                                            >
                                                Create Account
                                            </StyledButton>
                                            <br />
                                            {/* TODO do we want to configure GA for these btns? */}
                                            <Link to='/login' useWhiteLanding={useWhiteLanding}>
                                                Log In
                                            </Link>
                                            <Link to='/login-help' useWhiteLanding={useWhiteLanding}>
                                                Trouble Logging In?
                                            </Link>
                                            <br />
                                        </div>
                                        <div className={`${Classes.actionSmallText } ${useWhiteLanding ? Classes.csun : ''} `}>{isParentView ?
                                            'Available for students, staff, and parents at participating colleges and universities' :
                                            (isCompany || isCommunity || isTeamsApp)  ? '' : 'Available at participating colleges and universities'
                                        }</div>
                                    </div>
                                </div>
                            </div>
                            {/* Signup-specific footer */}
                            {footer}
                        </div>

                        {isParentView && (
                            <div style={{ color: 'white' }}>
                                {tertiaryText}
                            </div>
                        )}

                        <Footer />
                        <Snackbar />
                    </Box>
                </ScrollContainerLayout>
            </>
        );
    }
}

module.exports = withTheme(SignupEntry);

internals.StyledTextField = styled(TextField)`
    .MuiInputBase-root {
        color: ${(props) => props.inputColor || '#ffffff'};
    }
    .MuiFormHelperText-root.Mui-error {
        color:  ${(props) => props.errorColor || '#FF9696'};
    }
    .MuiInput-underline.Mui-error:after,.MuiInput-underline.Mui-error:before {
        border-bottom-color: ${(props) => props.errorColor || '#FF9696'};
    }
    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid ${(props) => props.highlightColor || 'rgb(224,224,224)'};
    }
    .MuiInput-underline:after {
        border-bottom: ${(props) => props.highlightColor || Colors.lightHighlight};
    }
`;

internals.SchoolTip = styled(Typography)`
    color: orange;
    && {
        margin-top: ${({ theme }) => theme.spacing(2)};
        margin-bottom: 10px;
    }
`;

internals.StyledButton = styled(Button)`
    border-radius: 40px;
    height: 3em;
    width: 100%;
    letter-spacing: 1px;
    &&& {
        background-color: ${(props) =>  props.backgroundColor || props.theme.palette.common.white};
        color: ${(props) =>  props.textColor || props.theme.palette.primary.main};
        margin-bottom: ${({ theme }) => theme.spacing(2)};
        font-family: ${Fonts.headerFont};
        font-weight: bold;
        text-transform: none;
        font-size: 1em;
        line-height: 3em;
    }
`;

internals.Link = function Link(props) {

    const {
        to,
        useWhiteLanding,
        children
    } = props;

    return (
        <div className={Classes.linkHolder}>
            <NavLink
                className={Classes.link}
                style={{
                    textDecoration: 'none',
                    borderBottomColor: `${useWhiteLanding ? '#000000' : ''}`,
                    color: `${useWhiteLanding ? '#333333' : ''}`
                }}
                to={to}
                data-focus-outline='radius:4,padding:5'
            >
                {children}
            </NavLink>
        </div>
    );
};

internals.Link.propTypes = {
    to: PropTypes.string,
    useWhiteLanding: PropTypes.bool,
    children: PropTypes.string
};

internals.styles = {
    floatingLabel:(useWhiteLanding) => ({
        color: useWhiteLanding ? '#333333' : Colors.lightHighlight,
        opacity: 1
    }),
    floatingLabelFocus: {
        color: Colors.lightHighlight,
        opacity: 'initial'
    },
    underlineFocus: {
        borderColor: Colors.lightHighlight
    },
    error: {
        position: 'absolute',
        bottom: '-.5em',
        fontSize: '.66em',
        lineHeight: '.66em',
        color: '#FF9696'
    },
    button: {
        backgroundColor: Colors.lightText,
        marginBottom: '15px',
        height: '3em',
        width: '100%'
    },
    buttonLabel: {
        fontFamily: Fonts.headerFont,
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '1em',
        letterSpacing: 1,
        lineHeight: '3em'
    },
    paddedLogo: {
        marginTop: '2em'
    }
};
