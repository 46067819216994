// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__iconBtnFocusVisible___tfOv4{background-color:hsla(0,0%,100%,.4)!important}`, "",{"version":3,"sources":["webpack://./src/components/MaterialMenu/styles.scss"],"names":[],"mappings":"AAAA,qCACE,6CAAgD","sourcesContent":[".iconBtnFocusVisible{\n  background-color:rgba(255,255,255,0.4)!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBtnFocusVisible": `styles__iconBtnFocusVisible___tfOv4`
};
export default ___CSS_LOADER_EXPORT___;
