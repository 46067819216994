const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const { default: UploadedFileIcon } = require('@mui/icons-material/Attachment');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: SelectField } = require('@mui/material/Select');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: InputLabel } = require('@mui/material/InputLabel');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const BottomButtonContainer = require('components/BottomButtonContainer');
const { default: Classes } = require('./styles.scss');
const { USER_ROLE_IDS } = require('utils/constants');
const { default: Checkbox } = require('@mui/material/Checkbox');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: TextField } = require('@mui/material/TextField');
const { default: Typography } = require('@mui/material/Typography');
const IsEmail = require('utils/is-email');

const { createRef } = React;

const MB_IN_BYTES = 1000000;

const internals = {
    mailTypes: [
        {
            value:'student',
            label:'Students'
        },
        {
            value:'staff',
            label:'Staff'
        },
        {
            value:'parent',
            label:'Parents'
        }
    ]
};

const StyledFormControl = Styled(FormControl)`
    width: 100%;
    margin: 0.2em 0em;
`;

module.exports = class PreapprovedEmailUpload extends React.PureComponent {

    static propTypes = {
        onSubmit: T.func.isRequired,
        schoolId: T.number.isRequired,
        schoolName: T.string.isRequired,
        schoolRoles: T.array
    }

    constructor(props) {

        super(props);

        this.state = {
            csv: null,
            allMailsType: '',
            notifyUsers: false
        };

        this.fields = ['allMailsType', 'csv', 'userByText', 'notifyUsers'];
        this.requiredFields = ['allMailsType'];

        this.error = this._error.bind(this);
        this.reset = this._reset.bind(this);
        this.selectFile = this._selectFile.bind(this);
        this.setFieldValue = this._setFieldValue.bind(this);
        this.setSelectValue = this._setSelectValue.bind(this);
        this.setCheckboxValue = this._setCheckboxValue.bind(this);
        this.submit = this._submit.bind(this);
        this.validate = this._validate.bind(this);

        this.fabInputRef = createRef();
    }

    showError(field, show) {

        show = (typeof show === 'undefined') ? this.validate(field) : show;

        return () => {

            return this.setState({ [`${field}ErrorShow`]: show });
        };
    }

    _error(field) {

        if (!this.state[`${field}ErrorShow`]) {
            return null;
        }


        if (field === 'allMailsType'){
            const error = this.validate(field);

            if (error !== null) {
                return <div id={this.id + '-' + field + '-error-message'} role='alert' aria-live='assertive'>{error}</div>;
            }

            return null;
        }

        return this.validate(field);
    }

    _selectFile(ev) {

        const newCsv = ev.target.files[0];
        if (newCsv === null) {
            return;
        }

        this.showError('csv')();
        this.setState({
            csv: newCsv
        });

        ev.target.value = null; // Reset so we can select the same file and still trigger an onChange
    }

    _setFieldValue(field) {

        return (ev, info) => {

            // Nullish operator accepts ''
            const value = info?.newValue ?? ev?.target?.value ?? ev;

            this.setState({
                [field]: value
            });
        };
    }

    _setCheckboxValue(field){

        return (event, index, value) => {

            this.setState({
                [field]: !this.state[field]
            });
        };
    }
    _setSelectValue(field) {

        return (event, index, value) => {

            if (field === 'allMailsType') {
                this.showError('allMailsType');
            }

            this.setState({
                [field]: event.target.value
            });
        };
    }

    _validate(field) {

        const value = this.state[field];

        if (this.requiredFields.find((f) => f === field) && (!value || value === '')) {
            if (field === 'allMailsType') {
                return 'User type is required!';
            }

            return field + ' is required!';
        }

        switch (field) {
            case 'userByText':
                if (value && !IsEmail(value)) {
                    return 'Invalid email address';
                }
                else if (!value && !this.state.csv) {
                    return 'Must specify user email here or upload CSV below';
                }

                break;
            case 'csv':
                if (!value && !this.state.userByText) {
                    return 'Must upload CSV here or specify user email above';
                }
                else if (!value) {
                    return null;
                }

                // eslint-disable-next-line no-case-declarations
                const match = value.name.match(/\.csv$/);
                if (match === null) {
                    return 'File must be a csv';
                }

                if (value.size > (4.9 * MB_IN_BYTES)) {
                    return 'csv must be under 5mb';
                }

                if (!this.state.userByText && this.state.userByTextErrorShow) {
                    this.showError('userByText', false)();
                }

                break;
            case 'allMailsType': {

                if (!this.state.allMailsType && (value === null || value === '' )) {
                    return 'All mails type is required';
                }

                break;
            }
        }

        return null;
    }

    _reset() {

        this.setState({
            allMailsType:'',
            userByText: '',
            csv: null,
            // hide errors (required field errors display on submit when still on form)
            ...this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;
                return collector;
            }, {})
        });
    }

    _submit(cb) {

        const hasErrors = this.fields.some(this.validate);

        if (hasErrors) {

            // On submit attempt, all errors are fair game to display

            return this.setState(this.fields.reduce((collector, field) => {

                collector[`${field}ErrorShow`] = false;

                if (this.validate(field)) {
                    collector[`${field}ErrorShow`] = true;
                }

                return collector;
            }, {}));
        }

        const payload = this.fields.reduce((collector, field) => {

            collector[field] = this.state[field];
            return collector;
        }, {});

        // No errors?  Submit the field/values

        const { schoolId, onSubmit } = this.props;
        onSubmit({ ...payload, schoolId }, cb || (() => null));
        this.reset();
    }

    render() {

        const { schoolName, schoolRoles } = this.props;
        const { csv, userByText, allMailsType } = this.state;
        const { FileUploadText, OrSpacer } = internals;

        const filteredSchoolRoles = schoolRoles.filter((schoolRole) => {

            return !USER_ROLE_IDS.SUPERUSER.includes(schoolRole.id);
        });

        const isCsvDisabled = !!userByText;

        return (
            <BottomButtonContainer
                btnLabel='Create&nbsp;User(s)'
                onBtnClick={this.submit}
                disabled={this.state.submitting}
            >
                <h2>Preapprove Users</h2>
                <div className={Classes.uploadFileInstructions}>
                    For <b>{schoolName}</b>
                </div>
                <div className={Classes.uploadFileInstructions}>
                    {'Enter a single email address to create one new user -OR- upload a CSV file containing multiple user emails for preapproval.'}
                </div>
                <div className={Classes.uploadFileInstructions}>
                    {'Make sure the CSV file contains only one column with a header row named \'EMAIL\'. Please select below which user type to create.'}
                </div>

                <StyledFormControl>
                    <div style={{ marginTop: 10 }}>
                        <FormControlLabel
                            label='Notify Users'
                            labelPlacement='end'
                            control={<Checkbox
                                checked={!!this.state.notifyUsers}
                                onChange={this.setCheckboxValue('notifyUsers')}
                            />}
                        />
                    </div>
                </StyledFormControl>
                <StyledFormControl>
                    <InputLabel  error={this.state.incomingClassErrorShow} id='user-type-select-input-label'>
                        User type *
                    </InputLabel>
                    <SelectField
                        // SelectField has width: 256px set as inline style
                        style={{ width: '100%' }}
                        id='user-type-select-input'
                        inputProps={{
                            'aria-labelledby': 'user-type-select-input-label'
                        }}
                        labelId='user-type-select-input-label'
                        value={allMailsType}
                        defaultValue={allMailsType}
                        error={this.state.allMailsTypeErrorShow}
                        onBlur={this.showError('allMailsType')}
                        onChange={this.setSelectValue('allMailsType')}
                        maxheight={220}
                    >
                        <MenuItem key='clear-selection' value=''>Select…</MenuItem>
                        {filteredSchoolRoles.map((schoolRole) => <MenuItem key={schoolRole.id} value={schoolRole.name}>{`${schoolRole.label}`}</MenuItem>)}
                    </SelectField>
                    {this.state.allMailsTypeErrorShow && <FormHelperText error={this.state.allMailsTypeErrorShow}>{this.error('allMailsType')}</FormHelperText>}
                </StyledFormControl>
                <StyledFormControl>
                    <TextField
                        fullWidth
                        label='Single Email Address'
                        id='user-text-input'
                        classes={{
                            root: Classes.textFieldRoot
                        }}
                        InputLabelProps={{
                            classes: {
                                root: Classes.textFieldInputLabelRoot
                            }
                        }}
                        error={this.state.userByTextErrorShow}
                        value={userByText}
                        inputProps={{ type: 'email' }}
                        onBlur={this.showError('userByText')}
                        onChange={this.setFieldValue('userByText')}
                    />
                    {this.state.userByTextErrorShow && <FormHelperText error={this.state.userByTextErrorShow}>{this.error('userByText')}</FormHelperText>}
                </StyledFormControl>
                <OrSpacer>
                    <Typography>{'-OR-'}</Typography>
                </OrSpacer>
                <div className={Classes.uploadFileWrapper}>
                    <div className={`${Classes.emptyUploadArea} ${isCsvDisabled ? Classes.disabledEmptyUploadArea : ''}`}>
                        <label className={`${Classes.uploadLabel} ${isCsvDisabled ? Classes.disabledUploadLabel : ''}`}>
                            {csv
                                ? (<div>
                                    <FileUploadText><UploadedFileIcon className={Classes.uploadedFileIcon} />{csv.name}</FileUploadText>
                                </div>)
                                : <FileUploadText>Click to Select a CSV File</FileUploadText>}
                            <input
                                disabled={isCsvDisabled}
                                type='file'
                                accept='text/csv'
                                onChange={this.selectFile}
                                style={{ visibility: 'hidden', width: '0px', height: '0px' }}
                            />
                        </label>
                    </div>
                </div>
                {this.error('csv') && <FormHelperText error={this.error('csv')}>{this.error('csv')}</FormHelperText>}
            </BottomButtonContainer>
        );
    }
};

internals.FileUploadText = Styled.div`
    text-decoration: underline;
`;

internals.OrSpacer = Styled.div`
    margin: 1em 0;
    text-align: center;
`;
