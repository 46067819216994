const { context } = require('app-context');

const redux = context.redux.hooks;
const selectors = context.selectors.all;

exports.getDevice = () => {

    const devicePlatform = selectors.getPlatformName(redux.getState());

    return /(android)/i.test(navigator.userAgent)
        ? 'android'
        : ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || devicePlatform === 'iPhone' || devicePlatform === 'iPad' || devicePlatform === 'iPhone Simulator' || devicePlatform === 'iPad Simulator' || devicePlatform === 'iOS')
            ? 'ios'
            : 'other';
};
