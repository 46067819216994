const { connect } = require('react-redux');
const { context } = require('app-context');
const CreateClassPage = require('../components/CreateClassPage');

const actions = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state),
        globalInputPageHasError: selectors.getInputPageHasErrors(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state)
    }),
    {
        onSubmit: actions.classes.create,
        onSubmitNoRedirect: actions.classes.createNoRedirect,
        setGlobalInputPageError: actions.app.setGlobalInputPageError,
        removeGlobalInputPageError: actions.app.removeGlobalInputPageError
    }
)(CreateClassPage);
