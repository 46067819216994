const { connect } = require('react-redux');
const { context } = require('../app-context');
const FilterDialog = require('components/FilterDialog');

const selectors = context.selectors.all;
const actions = context.actions;
module.exports = connect(
    (state) => ({
        interests: selectors.getInterests(state),
        majors: selectors.getMajorsList_forCurrentUser(state),
        incomingClassList: selectors.getIncomingClassList_forCurrentUser(state),
        graduatingClassList: selectors.getGraduatingClassList_forCurrentUser(state),
        isParent: selectors.isParentPermission(state),
        currentUserPermissions: selectors.getCurrentUserRolePermissions(state),
        availableRoleGroups: selectors.getRoleGroups(state),
        discoverSortType: selectors.getDiscoverSortType(state),
        departments: selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        officesList: selectors.getAllOffices(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        allRoles: selectors.getRoles(state),
        conditionalDisplayFields: selectors.getConditionalDisplayFields_forCurrentUser(state),
        classmateDiscoverSearchFilter: selectors.getClassmateDiscoverSearchFilter(state),
        badges: selectors.getAllBadges(state),
        appContextFilter: selectors.getAppContextFilter(state)
    }),
    {
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        resetSearch: (sortType) => (dispatch) => {

            dispatch(actions.app.setClassmateDiscoverSearchFilter({ filter : {} }));
            return dispatch(actions.dataFetching.fetchUserSearchResults.firstPage({
                allByMatches: true,
                sortType
            }));
        }
    }
)(FilterDialog);
