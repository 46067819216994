exports.networkErrs = [
    'Network Error',
    // Axios-specific errors
    'ERR_NETWORK',
    'ERR_TIMEOUT',
    'ERR_BAD_RESPONSE',
    'ERR_BAD_REQUEST',
    'ECONNABORTED',

    // Browser-specific errors (Chromium-based browsers)
    'ERR_INTERNET_DISCONNECTED',
    'ERR_CONNECTION_RESET',
    'ERR_CONNECTION_REFUSED',
    'ERR_NAME_NOT_RESOLVED',
    'ERR_ADDRESS_UNREACHABLE',
    'ERR_NETWORK_CHANGED',
    'ERR_TIMED_OUT',
    'ERR_TUNNEL_CONNECTION_FAILED',
    'ERR_EMPTY_RESPONSE',
    'ERR_FAILED',
    'ERR_ABORTED',
    'ERR_TOO_MANY_REDIRECTS',

    // SSL/TLS-related errors
    'ERR_CERT_AUTHORITY_INVALID',
    'ERR_SSL_PROTOCOL_ERROR',
    'ERR_SSL_CERT_COMMON_NAME_INVALID',
    'ERR_CERT_DATE_INVALID',
    'ERR_CERT_INVALID',
    'ERR_BAD_SSL_CLIENT_AUTH_CERT',
    'ERR_INSECURE_RESPONSE',

    // Proxy-related errors
    'ERR_PROXY_CONNECTION_FAILED',

    // General network errors
    'ENETUNREACH', // Network is unreachable
    'EHOSTUNREACH', // Host is unreachable
    'ECONNREFUSED', // Connection refused by the server
    'ETIMEDOUT', // Connection attempt timed out
    'ECONNRESET',
    'EAI_AGAIN',
    'ERR_NETWORK_IO_SUSPENDED',
    'ERR_NETWORK_ACCESS_DENIED',
    'ERR_NETWORK_DISCONNECTED',

    // Client-side errors
    'ERR_BLOCKED_BY_CLIENT',
    'ERR_BLOCKED_BY_RESPONSE'
];

exports.isOfflineError = (error) => {

    return exports.networkErrs.includes(error.code);
};
