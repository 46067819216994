const { connect } = require('react-redux');
const { context } = require('../app-context');

const AlertDialog = require('components/AlertDialog');

const actions   = context.actions;
const selectors = context.selectors.all;

module.exports = connect(
    (state) => ({
        alertDialogOpen: selectors.getAlertDialogOpen(state)
    }), {
        closeAlertDialog: actions.app.closeAlertDialog
    }
)(AlertDialog);
