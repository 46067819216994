// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___WCzoM{width:100%}.styles__header___Ohgip{align-items:center;display:flex;flex-flow:row wrap;height:120px;justify-content:space-between}.styles__header___Ohgip .styles__createNewButton___DX7q9{height:40px}@media (max-width:600px){.styles__header___Ohgip{padding:0 20px}}`, "",{"version":3,"sources":["webpack://./src/routes/app/surveys/components/SurveysManagement/styles.scss"],"names":[],"mappings":"AAAA,yBACE,UAAW,CACZ,wBAMC,kBAAa,CAJb,YAAa,CACb,kBAAmB,CACnB,YAAa,CACb,6BACmB,CALrB,yDAQI,WAAY,CACb,yBATH,wBAYI,cAAe,CAElB","sourcesContent":[".wrapper {\n  width: 100%;\n}\n.header{\n  display: flex;\n  flex-flow: row wrap;\n  height: 120px;\n  justify-content: space-between;\n  align-items: center;\n\n  .createNewButton{\n    height: 40px;\n  }\n\n  @media (max-width: 600px) {\n    padding: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___WCzoM`,
	"header": `styles__header___Ohgip`,
	"createNewButton": `styles__createNewButton___DX7q9`
};
export default ___CSS_LOADER_EXPORT___;
