// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__resultWrapperGrid___ftRVI{float:left;margin-bottom:4px;width:calc(50% - 2px)}.styles__resultWrapperGrid___ftRVI:nth-child(odd){margin-right:4px}@media screen and (max-width:600px){.styles__resultWrapperGrid___ftRVI{width:100%}.styles__resultWrapperGrid___ftRVI:nth-child(2n){margin-right:0}}.styles__resultWrapperGridContainer___dUU6s{display:flex;flex-wrap:wrap;overflow:hidden;padding:.5em 2em}@media screen and (max-width:960px){.styles__resultWrapperGridContainer___dUU6s{justify-content:center}}@media screen and (max-width:600px){.styles__resultWrapperGridContainer___dUU6s{padding:8px 22px}}.styles__imageList___oR8vU{transform:translateZ(0)}.styles__center___rxAZz{justify-content:center}.styles__flex-start___wABMO{justify-content:flex-start}`, "",{"version":3,"sources":["webpack://./src/components/SearchResultGrid/styles.scss"],"names":[],"mappings":"AAAA,mCACE,UAAW,CAEX,iBAAe,CADf,qBACkB,CAHpB,kDAMI,gBAAiB,CAClB,oCAPH,mCAUI,UAAW,CAVf,iDAaM,cAAe,CAChB,CAIL,4CAGE,YAAa,CACb,cAAW,CAHV,eAAgB,CACjB,gBAEe,CACf,oCALF,4CAMI,sBAAuB,CAK1B,CAHC,oCARF,4CASI,gBAAiB,CAEpB,CAED,2BACE,uBAAwB,CACzB,wBAGC,sBAAuB,CACxB,4BAGC,0BAA2B","sourcesContent":[".resultWrapperGrid {\n  float: left;\n  width: calc(50% - (4px / 2));\n  margin-bottom: 4px;\n\n  &:nth-child(odd) {\n    margin-right: 4px;\n  }\n\n  @media screen and (max-width: 600px) {\n    width: 100%;\n\n    &:nth-child(even) {\n      margin-right: 0;\n    }\n  }\n}\n\n.resultWrapperGridContainer {\n   overflow: hidden;\n  padding: 0.5em 2em;\n  display: flex;\n  flex-wrap: wrap;\n  @media screen and (max-width: 960px) {\n    justify-content: center;\n  }\n  @media screen and (max-width: 600px) {\n    padding: 8px 22px;\n  }\n}\n\n.imageList{\n  transform: translateZ(0);\n}\n\n.center{\n  justify-content: center;\n}\n\n.flex-start{\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultWrapperGrid": `styles__resultWrapperGrid___ftRVI`,
	"resultWrapperGridContainer": `styles__resultWrapperGridContainer___dUU6s`,
	"imageList": `styles__imageList___oR8vU`,
	"center": `styles__center___rxAZz`,
	"flex-start": `styles__flex-start___wABMO`
};
export default ___CSS_LOADER_EXPORT___;
