const React = require('react');
const PropTypes = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const { default: FlatButton } = require('@mui/material/Button');
const { default: Classes } = require('./styles.scss');
const Fonts = require('styles/fonts.json');
const Colors = require('styles/colors.json');
const { gaTemplates } = require('utils/react-ga');
const Logomark = require('static/nearpeer_n_white.svg');
const NearpeerMobile = require('static/nearpeer-mobile.png');
// iOS-only image is for app store compliance
const NearpeerMobileiOS = require('static/nearpeer-mobile-ios.png');
const PlayStore = require('static/google_play.svg');
const AppStore = require('static/app_store.svg');
const ScrollContainerLayout = require('layouts/ScrollContainerLayout');
const Footer = require('components/Footer');
const NearpeerMobileBusinessiOS = require('static/nearpeer-mobile-business-ios-new.png');
const NearpeerMobileBusiness = require('static/nearpeer-mobile-business-no-bg.png');
const { default: styled } = require('styled-components');
const { useEffect } = require('react');
const Snackbar = require('containers/Snackbar');
const PageTitleByPath = require('utils/pageTitleByPath');

const StyledButton = styled(FlatButton)({
    backgroundColor: Colors.lightText,
    marginBottom: '15px',
    height: '3em',
    width: '100%',
    '&:hover': {
        backgroundColor: Colors.lightText
    },
    fontFamily: Fonts.headerFont,
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '1em',
    letterSpacing: 1,
    lineHeight: '3em'
});

const Home = (props) => {

    const {
        isAuthenticated = false,
        onLogout = () => null,
        platformName,
        navigate
    } = props;

    const { pathname: path } = window.location;

    const pageTitle = PageTitleByPath(path);

    useEffect(() => {

        if (isAuthenticated) {
            navigate('/app/welcome');
        }
    }, [isAuthenticated, navigate]);

    const isTeamsApp = process.env.APP_FLAVOUR === 'teams';

    const devicePlatform = platformName && platformName;
    const device = /(android)/i.test(navigator.userAgent)
        ? 'android'
        : ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || devicePlatform === 'iPhone' || devicePlatform === 'iPad' || devicePlatform === 'iPhone Simulator' || devicePlatform === 'iPad Simulator' || devicePlatform === 'iOS')
            ? 'ios'
            : 'other';

    const logout = () => {

        gaTemplates.buttons('logout', 'landing page: logout');
        return onLogout();
    };

    return <ScrollContainerLayout>
        <div className={Classes.bodyContainer}>

            <div className={Classes.homeMain} role='main'>

                <div className={Classes.logoContainer}>
                    <div className={Classes.logomarkContainer}>
                        <img src={Logomark} alt='' className={Classes.logomark} />
                        <div className={Classes.tm}>™</div>
                    </div>
                    <h1 aria-label={pageTitle} className={Classes.header}>Nearpeer</h1>
                </div>

                <div className={Classes.subtitle}>{'Life is better when you\'re part of the community'}</div>

                <div className={Classes.homeContent}>
                    <div className={Classes.mobileScreensContainer}>
                        <img
                            className={Classes.mobileScreens}
                            src={isTeamsApp ? (device === 'ios' ? NearpeerMobileBusinessiOS : NearpeerMobileBusiness) : (device === 'ios' ? NearpeerMobileiOS : NearpeerMobile)}
                            alt='Nearpeer'
                        />
                    </div>
                    <div className={Classes.homeActions}>
                        {!window.cordova && <div className={Classes.getApps}>
                            <div className={Classes.actionText}>Get the app:</div>
                            {
                                // Only show Play Store link on Android devices, and only App Store link on iOS devices.
                                // Also, incorporate a slight style change if only one button is shown (Classes.onPhone)
                            }
                            <div className={`${Classes.getAppsButtons} ${device !== 'other' && Classes.onPhone}`}>
                                {device !== 'android' && (

                                    (isTeamsApp) ? <a
                                        onClick={() => gaTemplates.buttons('landing page', 'landing page: get ios app')}
                                        className={Classes.getAppsButton}
                                        href="https://itunes.apple.com/us/app/nearpeer-for-teams/id6445984458"
                                        rel="noreferrer"
                                        target={'_blank'}
                                        data-focus-outline='radius:5,padding:2'
                                    >
                                        <img src={AppStore} alt="AppStore" />
                                    </a> : <a
                                        onClick={() => gaTemplates.buttons('landing page', 'landing page: get ios app')}
                                        className={Classes.getAppsButton}
                                        href="https://itunes.apple.com/us/app/nearpeer-for-college-students/id1300243604"
                                        rel="noreferrer"
                                        target={'_blank'}
                                        data-focus-outline='radius:5,padding:2'
                                    >
                                        <img src={AppStore} alt="AppStore" />
                                    </a>
                                )}
                                {device !== 'ios' && (
                                    isTeamsApp  ? <a
                                        onClick={() => gaTemplates.buttons('landing page', 'landing page: get android app')}
                                        className={Classes.getAppsButton}
                                        href="https://play.google.com/store/apps/details?id=net.nearpeer.app_business"
                                        rel="noreferrer"
                                        target={'_blank'}
                                        data-focus-outline='radius:5,padding:2'
                                    >
                                        <img src={PlayStore} alt="PlayStore" />
                                    </a> : <a
                                        onClick={() => gaTemplates.buttons('landing page', 'landing page: get android app')}
                                        className={Classes.getAppsButton}
                                        href="https://play.google.com/store/apps/details?id=net.nearpeer.app"
                                        rel="noreferrer"
                                        target={'_blank'}
                                        data-focus-outline='radius:5,padding:2'
                                    >
                                        <img src={PlayStore} alt="PlayStore" />
                                    </a>
                                )}
                            </div>
                        </div>}
                        <div className={Classes.signupContainer}>
                            {isAuthenticated ? (
                                <StyledButton
                                    onClick={logout}
                                    color={'primary'}
                                    data-focus-outline='radius:40,padding:4'
                                >
                                    Log Out
                                </StyledButton>
                            ) : (
                                <div>
                                    <div className={Classes.actionText}>Sign up or sign in:</div>
                                    <Link to='/signup' tabIndex={-1}>
                                        <StyledButton
                                            onClick={() => gaTemplates.buttons('create account', 'landing page: create account')}
                                            color={'primary'}
                                            data-focus-outline='radius:40,padding:4'
                                        >
                                            Create Account
                                        </StyledButton>
                                    </Link>
                                    <br />
                                    <div      className={Classes.linkHolder}>
                                        <Link
                                            className={Classes.link}
                                            style={{ textDecoration: 'none' }}
                                            to='/login'
                                            data-focus-outline='radius:4,padding:5'
                                        >
                                            Log In
                                        </Link>
                                    </div>
                                    <br />

                                    {/* <FlatButton
                                            label='Log In'
                                            containerElement={<Link to='/login' />}
                                            onClick={() => gaTemplates.buttons('login', 'landing page: login')}
                                            style={styles.button}
                                            labelStyle={styles.buttonLabel}
                                            secondary
                                        />*/}
                                </div>
                            )}
                            <div className={Classes.actionSmallText}>Available for students and staff at participating colleges and universities</div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <Snackbar />
        </div>
    </ScrollContainerLayout>;
};

Home.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    platformName: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired
};

Home.defaultProps = {
    isAuthenticated: PropTypes.bool.isRequired,
    platformName: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired
};

module.exports = Home;
