const { push: Push } = require('connected-react-router');
const { connect } = require('react-redux');
const { context } = require('app-context');
const EditPage = require('../components/EditPage');
const { safeWarning } = require('assertions-simplified');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;

module.exports = connect(
    (state) => ({
        interestsLoading: selectors.getIsLoadingInterests(state),
        officesLoading: selectors.getIsLoadingOffices(state),
        yearsHiredLoading: selectors.getIsLoadingYearsHired(state),
        preferencesLoading: selectors.getIsLoadingPreferences(state),
        onCampusHousingList: selectors.getOnCampusHousingList(state) || [],
        offCampusHousingList: selectors.getOffCampusHousingList(state) || [],
        transfers: selectors.getBasicTransfers_forProfileEditPage(state),
        signupDetails: selectors.getSignupDetails_forProfileEditPage(state),
        majors: selectors.getMajorsList_forCurrentUser(state),
        departments: selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        officesList: selectors.getAllOffices(state),
        incomingClassList: selectors.getIncomingClassList_forCurrentUser(state),
        graduatingClassList: selectors.getGraduatingClassList_forCurrentUser(state),
        badges: selectors.getBadgesForCurrentUser(state),
        conditionalDisplayFields: selectors.getConditionalDisplayFields_forCurrentUser(state),
        isStudentRoleGroup: selectors.isStudentRoleGroup(state,selectors.getCurrentUserId(state)),
        isParent: selectors.isParentPermission(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        role: selectors.getCurrentUserRole(state),
        schoolRoles: selectors.getSchoolRoles(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        signupContext: selectors.getSignupContext(state),
        fieldsPlaceholders: selectors.getFieldsPlaceholders(state),
        useProfanityFilter: selectors.getShouldUseProfanityFilter(state)
    }), {
        onSubmit: (signupDetails, redirectPath = '/app/welcome') => (dispatch) => { // eslint-disable-line

            const profileDiff = selectors.getUserProfileDiff_forUpdateProfile(redux.getState(), signupDetails);

            dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

            return dispatch(actions.profileManagement.updateProfile(profileDiff))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile failed');
                }

                dispatch(Push(redirectPath));

                dispatch(actions.alerts.notification.push({
                    message: 'Profile updated'
                }));
            })
            .catch(safeWarning);
        },
        onSubmitNoRedirect: (signupDetails) => (dispatch) => { // eslint-disable-line

            const profileDiff = selectors.getUserProfileDiff_forUpdateProfile(redux.getState(), signupDetails);

            dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

            return dispatch(actions.profileManagement.updateProfile(profileDiff))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile failed');
                }

                dispatch(actions.alerts.notification.push({
                    message: 'Profile updated'
                }));
            })
            .catch(safeWarning);
        },
        onInvalidSubmit: () => actions.alerts.notification.push({ message: 'Please fix invalid form fields' }),
        saveProfilePicture: (profilePictureDetails) => (dispatch, getState) => { // eslint-disable-line

            const profilePictureDiff = selectors.getUserProfilePictureDiff_forUpdateProfile(redux.getState(), profilePictureDetails);

            dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

            return dispatch(actions.profileManagement.updateProfilePicture(profilePictureDiff))
            .then((success) => {

                // The action creator resolves undefined on failure
                if (!success) {
                    throw new Error('Update profile picture failed');
                }

                dispatch(actions.alerts.notification.push({
                    message: 'Profile picture updated'
                }));

                return success;
            })
            .catch(safeWarning);
        }
    }
)(EditPage);
