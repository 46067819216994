// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___CP5E1{width:100%}.styles__topRow___SAtmo{align-items:center;display:flex;justify-content:flex-start}.styles__innerWrapper___VYMSH{display:flex;flex-flow:column nowrap;padding:1.5em;width:100%}.styles__innerWrapper___VYMSH>div{width:100%!important}.styles__innerWrapper___VYMSH h2{margin:0}.styles__fullWidth___PGVv8{width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/app/surveys/components/SurveyCreatePage/styles.scss"],"names":[],"mappings":"AAEA,yBACE,UAAW,CACZ,wBAIC,kBAAmB,CADnB,YAAa,CAEb,0BAA2B,CAC5B,8BAKC,YAAa,CACb,uBAAW,CAFX,aAAc,CADd,UAGwB,CAJ1B,kCAOI,oBAAsB,CAP1B,iCAWI,QAAS,CACV,2BAID,UAAW","sourcesContent":["@import \"../../../../../styles/colors.json\";\n\n.wrapper {\n  width: 100%;\n}\n\n.topRow{\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.innerWrapper {\n  width: 100%;\n  padding: 1.5em;\n  display: flex;\n  flex-flow: column nowrap;\n\n  & > div {\n    width: 100% !important;\n  }\n\n  h2 {\n    margin: 0;\n  }\n}\n\n.fullWidth {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___CP5E1`,
	"topRow": `styles__topRow___SAtmo`,
	"innerWrapper": `styles__innerWrapper___VYMSH`,
	"fullWidth": `styles__fullWidth___PGVv8`
};
export default ___CSS_LOADER_EXPORT___;
