const React = require('react');
const T = require('prop-types');
const IsEqual = require('lodash/isEqual');
const BottomButtonContainer = require('components/BottomButtonContainer');
const EditClassContents = require('components/EditClassContents');

const { default: Classes } = require('./styles.scss');
const PreventNavigationDialog = require('components/PreventNavigationDialog');

const internals = {};

module.exports = class EditClassPage extends React.Component {

    static propTypes = {
        classDetails: T.object,
        onSubmit: T.func.isRequired,
        globalInputPageHasError: T.bool,
        schoolRoles: T.array.isRequired,
        setGlobalInputPageError: T.func,
        removeGlobalInputPageError: T.func,
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool
    }

    constructor(props) {

        super(props);

        this.stillExists = true;
        this.state = {
            pageIsDirty:false,
            submitting: false,
            _class: null,
            selectedRoles: null
        };

        this.act = this._act.bind(this);
        this.onKeyDown = this._onKeyDown.bind(this);
        this.submit = this._submit.bind(this);
    }


    shouldComponentUpdate(nextProps,nextState) {
        // Rendering the component only if
        // passed props value is changed
        if (nextProps.classDetails && nextProps.classDetails.class && !IsEqual(nextProps.classDetails.class,this.props.classDetails.class)) {

            return true;
        }

        if (!IsEqual(nextState,this.state)) {

            return true;
        }


        return false;

    }

    static getDerivedStateFromProps(props, state) {

        return {
            selectedRoles: state.selectedRoles === null ? props.classDetails.class.roles : state.selectedRoles
        };
    }

    componentWillUnmount() {

        this.stillExists = false;
    }

    _submit() {

        this.setState({ submitting: true });

        if (EditClassContents.allValid(this.state._class)){
            this.props.removeGlobalInputPageError();
            return this.props.onSubmit({
                id: this.props.classDetails.class.id,
                ...this.state._class
            }).then(() => {

                if (this.stillExists) {
                    this.setState({ submitting: false,pageIsDirty:false });
                }
            });
        }


        this.props.setGlobalInputPageError();
        this.setState({ submitting: false });

        return null;
    }

    _onKeyDown(ev) {

        // 13 === 'enter' key
        if (ev.keyCode === 13) {

            this.submit();
        }
    }

    _act(field, value) {

        if (field === 'roles'){

            this.setState({
                selectedRoles: value,
                _class: {
                    ...this.state._class,
                    [field]: value
                },
                pageIsDirty:true
            });
        }
        else {
            this.setState({
                _class: {
                    ...this.state._class,
                    [field]: value
                },
                pageIsDirty:true
            });
        }

    }

    render() {

        if (!this.props.classDetails) {
            return null;
        }

        const {
            globalInputPageHasError,
            classDetails: { class: _class },
            schoolRoles,
            isCompany,
            isCommunity,
            isSchoolOnline
        } = this.props;

        const { submitting, _class: stateClass, selectedRoles } = this.state;

        return (
            <BottomButtonContainer
                btnLabel='Save'
                onBtnClick={this.submit}
                disabled={(stateClass && !EditClassContents.allValid(stateClass)) || submitting || !stateClass}
            >
                <PreventNavigationDialog
                    // When should shouldBlockNavigation be invoked,
                    // simply passing a boolean
                    // (same as "when" prop of Prompt of React-Router)
                    saveFunction={this.submit}
                    pageHasErrors={globalInputPageHasError}
                    alertMsg={'You have made changes.\n Do you want to save or discard them?'}
                    when={this.state.pageIsDirty && !submitting}
                    // Navigate function
                    // eslint-disable-next-line react/prop-types
                    navigate={(path,state) => this.props.history.push(path,state)}
                    // Use as "message" prop of Prompt of React-Router
                    shouldBlockNavigation={(location) => {

                        if (this.state.pageIsDirty || globalInputPageHasError) {
                            return true;
                        }

                        return false;
                    }}
                />
                <div className={Classes.wrapper}>
                    <div className={Classes.innerWrapper}>
                        <h2>Edit {_class.name}</h2>
                        <EditClassContents
                            _class={_class}
                            act={this.act}
                            onKeyDown={this.onKeyDown}
                            schoolRoles={schoolRoles}
                            selectedRoles={selectedRoles}
                            setGlobalInputPageError={this.props.setGlobalInputPageError}
                            removeGlobalInputPageError={this.props.removeGlobalInputPageError}
                            globalInputPageHasError={globalInputPageHasError}
                            isCompany={isCompany}
                            isCommunity={isCommunity}
                            isSchoolOnline={isSchoolOnline}
                        />
                    </div>
                </div>
            </BottomButtonContainer>
        );
    }
};
