// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__customHousing___xIsDI{align-items:center;display:flex!important;justify-content:space-between;width:auto!important}`, "",{"version":3,"sources":["webpack://./src/components/GooglePlaceAutocomplete/styles.scss"],"names":[],"mappings":"AAIA,+BAEI,kBAAmB,CADnB,sBAAwB,CAExB,6BAA8B,CAC9B,oBAAsB","sourcesContent":["// This will be the <li> on the MaterialReactAutocomplete MenuItem\n\n@import \"../../styles/colors.json\";\n\n.customHousing {\n    display: flex !important;\n    align-items: center;\n    justify-content: space-between;\n    width: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customHousing": `styles__customHousing___xIsDI`
};
export default ___CSS_LOADER_EXPORT___;
