// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__legalContents___cpcd5{border-radius:0!important;flex:1;font-size:1.25rem;padding:.5em 0 50px;user-select:text;width:100%}@media (max-width:768px){.styles__legalContents___cpcd5{font-size:15px}}`, "",{"version":3,"sources":["webpack://./src/routes/legal/components/PrivacyPolicy/styles.scss"],"names":[],"mappings":"AACA,+BAII,yBAA2B,CAQ3B,MAAO,CANP,iBAAkB,CAHlB,mBAAoB,CAFpB,gBAAiB,CAYjB,UAAW,CALX,yBARJ,+BASQ,cAAe,CAKtB","sourcesContent":["\n.legalContents {\n    user-select: text;\n\n    padding: .5em 0 50px; // Need more padding at bottom to ensure content is available when vh is greater than the actual viewport height (iOS Safari)\n    border-radius: 0 !important;\n\n    font-size: 1.25rem;\n\n    @media (max-width: 768px) {\n        font-size: 15px;\n    }\n\n    flex: 1;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legalContents": `styles__legalContents___cpcd5`
};
export default ___CSS_LOADER_EXPORT___;
