const { APP: Types, AUTH, DATA_FETCHING } = require('../action-types');
const { USERS_SORT_TYPE } = require('../utils/constants');
const Storage = require('../utils/storage');

const now = Date.now();

const initialState = {
    platform: 'unknown',
    mainAppLoading: true,
    appContextFilter: 'all',
    discoverSortType: USERS_SORT_TYPE.BEST_MATCH,
    surveysManagementFilter: 'active',
    notificationsOpen: false,
    userReviewAvailableStatus: false,
    logoutDialogOpen: false,
    alertDialogOpen: false,
    inputPageHasErrors: false,
    connectionsFilter: '',
    interestsSearchText: '',
    classmateDiscoverSearchFilter: {},
    notifySearchFilter: {},
    surveySearchFilter: {},
    inBackground: false,
    modalOpen: false,
    historyPathname: '/',
    historyPreviousPathname: '/',
    rememberedScroll: { discover: null, group: null },
    shouldRefreshSearch: { discover: true, group: true },
    refreshSearchTimestamps: { discover: now, group: now },
    collapseHeaders: Storage.session.get('collapse-headers') || false
};

const internals = {};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        // Reset app state on logout
        case AUTH.LOGOUT.SUCCESS:
        case AUTH.CLOSE_ACCOUNT.SUCCESS:
        case AUTH.ACCOUNT_CLOSED_CLEAR:
            return initialState;
        case Types.SET_APP_PLATFORM:
            return {
                ...state,
                platform: action.payload.platformName
            };
        case Types.SET_MAIN_APP_LOADING:
            return {
                ...state,
                mainAppLoading: action.payload.appLoading
            };
        case Types.SET_USER_REVIEW_STATUS:
            return {
                ...state,
                userReviewAvailableStatus: action.payload.status
            };
        case Types.UPDATE_APP_CONTEXT_FILTER:
            return {
                ...state,
                appContextFilter: action.payload.filter
            };
        case Types.UPDATE_DISCOVER_SORT_TYPE:
            return {
                ...state,
                discoverSortType: action.payload.type
            };
        case Types.UPDATE_SURVEYS_MANAGEMENT_FILTER:
            return {
                ...state,
                surveysManagementFilter: action.payload.filter
            };
        case Types.UPDATE_CONNECTIONS_FILTER:
            return {
                ...state,
                connectionsFilter: action.payload.filter
            };
        case Types.UPDATE_CLASSMATE_DISCOVER_SEARCH_FILTER:
            return {
                ...state,
                classmateDiscoverSearchFilter: action.payload.filter
            };
        case Types.UPDATE_NOTIFY_SEARCH_FILTER:
            return {
                ...state,
                notifySearchFilter: action.payload.filter
            };
        case Types.UPDATE_SURVEY_SEARCH_FILTER:
            return {
                ...state,
                surveySearchFilter: action.payload.filter
            };
        case Types.SET_FIREBASE_TOKEN:
            return {
                ...state,
                firebaseToken: action.payload.token
            };
        case Types.SET_IN_BACKGROUND:
            return {
                ...state,
                inBackground: action.payload.inBackground
            };
        case Types.SET_INTERESTS_SEARCH_TEXT:
            return {
                ...state,
                interestsSearchText: action.payload.interestsSearchText
            };
        case Types.OPEN_NOTIFICATIONS:
            return {
                ...state,
                notificationsOpen: true
            };
        case Types.CLOSE_NOTIFICATIONS:
            return {
                ...state,
                notificationsOpen: false
            };
        case Types.OPEN_LOGOUT_DIALOG:
            return {
                ...state,
                logoutDialogOpen: true
            };
        case Types.CLOSE_LOGOUT_DIALOG:
            return {
                ...state,
                logoutDialogOpen: false
            };
        case Types.OPEN_ALERT_DIALOG:
            return {
                ...state,
                alertDialogOpen: true
            };
        case Types.CLOSE_ALERT_DIALOG:
            return {
                ...state,
                alertDialogOpen: false
            };
        case Types.INPUT_PAGE_SET_ERROR:
            return {
                ...state,
                inputPageHasErrors: true
            };
        case Types.INPUT_PAGE_REMOVE_ERROR:
            return {
                ...state,
                inputPageHasErrors: false
            };
        case Types.OPEN_MODAL:
            return {
                ...state,
                modalOpen: true
            };
        case Types.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false
            };
        case Types.SET_HISTORY_PATHNAME:
            return {
                ...state,
                historyPathname: action.payload.pathname
            };
        case Types.SET_HISTORY_PREVIOUS_PATHNAME:
            return {
                ...state,
                historyPreviousPathname: action.payload.pathname
            };
        case Types.REMEMBER_SCROLL:
            return {
                ...state,
                rememberedScroll: {
                    ...state.rememberedScroll,
                    ...action.payload.type
                }
            };
        case Types.RESTORE_SCROLL:
            return {
                ...state,
                rememberedScroll: {
                    ...state.rememberedScroll,
                    [action.payload.type]: null
                }
            };
        case Types.SHOULD_REFRESH_SEARCH:
            return {
                ...state,
                shouldRefreshSearch: {
                    ...state.shouldRefreshSearch,
                    ...action.payload
                },
                ...internals.mergeIf(action.payload.discover && action.payload.group, {
                    rememberedScroll: { discover: null, group: null }
                })
            };
        case DATA_FETCHING.FETCH_CLASS_USER_SEARCH_RESULTS.FIRST_PAGE.REQUEST:
            return {
                ...state,
                rememberedScroll: {
                    ...state.rememberedScroll,
                    group: null
                },
                refreshSearchTimestamps: {
                    ...state.refreshSearchTimestamps,
                    group: Date.now()
                }
            };
        case DATA_FETCHING.FETCH_USER_SEARCH_RESULTS.FIRST_PAGE.REQUEST:
            return {
                ...state,
                rememberedScroll: {
                    ...state.rememberedScroll,
                    discover: null
                },
                refreshSearchTimestamps: {
                    ...state.refreshSearchTimestamps,
                    discover: Date.now()
                }
            };
        case Types.SET_COLLAPSE_HEADERS:
            Storage.session.set('collapse-headers', action.payload.setting);
            return {
                ...state,
                collapseHeaders: action.payload.setting
            };
        default:
            return state;
    }
};

const selectors = {
    getPlatformName: (state) => state.platform,
    getMainAppLoading: (state) => state.mainAppLoading,
    getFirebaseToken: (state) => state.firebaseToken,
    getDiscoverSortType: (state) => state.discoverSortType,
    getNotificationsOpen: (state) => !!state.notificationsOpen,
    getUserReviewAvailableStatus: (state) => !!state.userReviewAvailableStatus,
    getLogoutDialogOpen: (state) => !!state.logoutDialogOpen,
    getAlertDialogOpen: (state) => !!state.alertDialogOpen,
    getInputPageHasErrors: (state) => !!state.inputPageHasErrors,
    getIsAppInBackground: (state) => state.inBackground,
    getModalIsOpen: (state) => state.modalOpen,
    getAppContextFilter: (state) => state.appContextFilter,
    getConnectionsFilter: (state) => state.connectionsFilter,
    getClassmateDiscoverSearchFilter: (state) => state.classmateDiscoverSearchFilter,
    getNotifySearchFilter: (state) => state.notifySearchFilter,
    getSurveySearchFilter: (state) => state.surveySearchFilter,
    getSurveysManagementFilter: (state) => state.surveysManagementFilter,
    getInterestsSearchText: (state) => state.interestsSearchText,
    getCollapseHeaders: (state) => state.collapseHeaders
};

module.exports = Object.assign(reducer, { selectors });

internals.mergeIf = (condition, obj) => {

    if (condition) {
        return obj;
    }

    return {};
};
