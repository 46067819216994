// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___bRBIf{width:100%}.styles__innerWrapper___ahnSo{display:flex;flex-flow:column nowrap;width:100%}.styles__innerWrapper___ahnSo>div{width:100%!important}.styles__innerWrapper___ahnSo h2{margin:0}.styles__fullWidth___J3Smn{width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/app/surveys/components/SurveysContainer/styles.scss"],"names":[],"mappings":"AAEA,yBACE,UAAW,CACZ,8BAIC,YAAa,CACb,uBAAW,CAFX,UAEwB,CAH1B,kCAMI,oBAAsB,CAN1B,iCAUI,QAAS,CACV,2BAID,UAAW","sourcesContent":["@import \"../../../../../styles/colors.json\";\n\n.wrapper {\n  width: 100%;\n}\n\n.innerWrapper {\n  width: 100%;\n  display: flex;\n  flex-flow: column nowrap;\n\n  & > div {\n    width: 100% !important;\n  }\n\n  h2 {\n    margin: 0;\n  }\n}\n\n.fullWidth {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles__wrapper___bRBIf`,
	"innerWrapper": `styles__innerWrapper___ahnSo`,
	"fullWidth": `styles__fullWidth___J3Smn`
};
export default ___CSS_LOADER_EXPORT___;
