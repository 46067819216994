const React = require('react');
const T = require('prop-types');
const BottomButtonContainer = require('components/BottomButtonContainer');
const CreateClassContents = require('components/CreateClassContents');

const { default: Classes } = require('./styles.scss');
const PreventNavigationDialog = require('components/PreventNavigationDialog');

const internals = {};

module.exports = class CreateClassPage extends React.PureComponent {

    static propTypes = {
        schoolName: T.string,
        globalInputPageHasError: T.bool,
        isCompany: T.bool,
        isCommunity: T.bool,
        isSchoolOnline: T.bool,
        onSubmit: T.func.isRequired,
        onSubmitNoRedirect: T.func.isRequired,
        schoolRoles: T.array.isRequired,
        setGlobalInputPageError: T.func,
        removeGlobalInputPageError: T.func
    }

    constructor(props) {

        super(props);

        this.stillExists = true;
        this.state = {
            submitting: false,
            pageIsDirty:false,
            class_: {
                type: 'group',
                name: '',
                emojiSymbol: '',
                facilitator: '',
                shortDescription: '',
                additionalDescription: '',
                classLocation: '',
                number: '',
                private: false,
                room: '',
                campus: '',
                section: '',
                semester: '',
                credits: '',
                misc: '',
                isAnnouncement: false,
                roles: []
            }
        };

        this.act = this._act.bind(this);
    }

    componentWillUnmount() {

        this.stillExists = false;
    }

    submit = async (submitFunc) => {

        this.setState({ submitting: true });

        if (CreateClassContents.allValid(this.state.class_)){
            this.props.removeGlobalInputPageError();
            await submitFunc(this.state.class_).then(() => {

                if (this.stillExists) {
                    this.setState({ submitting: false, pageIsDirty: false });
                }
            });

            return null;
        }

        this.props.setGlobalInputPageError();
        this.setState({ submitting: false });

        return null;
    }

    _act(field, value) {

        this.setState({
            pageIsDirty: true,
            class_: {
                ...this.state.class_,
                [field]: value
            }
        });
    }

    render() {

        const { class_, submitting } = this.state;

        const {
            globalInputPageHasError,
            schoolRoles,
            isCompany,
            isCommunity,
            isSchoolOnline
        } = this.props;

        return (
            <BottomButtonContainer
                btnLabel='Create'
                onBtnClick={() => this.submit(this.props.onSubmit)}
                disabled={!CreateClassContents.allValid(class_) || submitting}
            >
                <PreventNavigationDialog
                    // When should shouldBlockNavigation be invoked,
                    // simply passing a boolean
                    // (same as "when" prop of Prompt of React-Router)
                    saveFunction={() => this.submit(this.props.onSubmitNoRedirect)}
                    pageHasErrors={globalInputPageHasError}
                    alertMsg={'You have made changes.\n Do you want to save or discard them?'}
                    when={this.state.pageIsDirty && !submitting}
                    // Navigate function
                    // eslint-disable-next-line react/prop-types
                    navigate={(path,state) => this.props.history.push(path,state)}
                    // Use as "message" prop of Prompt of React-Router
                    shouldBlockNavigation={(location) => {

                        if (this.state.pageIsDirty || globalInputPageHasError) {
                            return true;
                        }

                        return false;
                    }}
                />
                <div className={Classes.wrapper}>
                    <div className={Classes.innerWrapper}>
                        <h2>Create a group or class for {this.props.schoolName}</h2>
                        <CreateClassContents
                            class_={class_}
                            schoolRoles={schoolRoles}
                            act={this.act}
                            setGlobalInputPageError={this.props.setGlobalInputPageError}
                            removeGlobalInputPageError={this.props.removeGlobalInputPageError}
                            isCompany={isCompany}
                            isCommunity={isCommunity}
                            isSchoolOnline={isSchoolOnline}
                        />
                    </div>
                </div>
            </BottomButtonContainer>
        );
    }
};
